* {
  box-sizing: border-box;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

$graphic: "Graphik Bold";

@font-face {
  font-family: "Bebas Neue Regular";
  src: local("Bebas Neue Regular"),
    url("./fonts/BebasNeue/bebas-neue.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Graphik Medium";
  src: local("Graphik Medium"),
    url("./fonts/Graphik/graphik-medium-webfont.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Graphik Semibold";
  src: local("Graphik Semibold"),
    url("./fonts/Graphik/Graphik-Semibold-Web.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Graphik Bold";
  src: local("Graphik Bold"),
    url("./fonts/Graphik/Graphik-Bold-Web.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
h1,
h2,
h3,
h4 {
  font-family: "Graphik Bold", "Segoe UI", Roboto, "Helvetica Neue", Arial,
    sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-weight: 500;
  color: #385a80;
}

h1 {
  font-family: "Graphik Bold", "Segoe UI", Roboto, "Helvetica Neue", Arial,
    sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 60px;
  padding-bottom: 0px !important;
  line-height: 55px;
}

h2 {
  font-size: 50px;
  margin-bottom: 0px !important;
}

h3 {
  font-family: "Graphik Medium", "Segoe UI", Roboto, "Helvetica Neue", Arial,
    sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 18px;
  letter-spacing: 0.5px;
  line-height: 24px;
  color: #385a80;
  text-align: center;
}

p {
  font-family: "Graphik Medium", "Segoe UI", Roboto, "Helvetica Neue", Arial,
    sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 18px;
  letter-spacing: 0.5px;
  line-height: 24px;
  color: #385a80;
  margin-bottom: 0px;
}

ul {
  padding: 0px;
  margin: 0px;
  list-style-type: none;
}

.login-noscript {
  display: block;
  padding: 15px 30px;
  font-family: "Graphik Bold", "Segoe UI", Roboto, "Helvetica Neue", Arial,
    sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  color: #ffffff;
  background: #ed5565;
}

/* Firefox */
.fb-page > span {
  display: block;
  padding: 15px 30px;
  font-family: "Graphik Bold", "Segoe UI", Roboto, "Helvetica Neue", Arial,
    sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  color: #ffffff;
  background: #ed5565;
}

a.nav-link {
  cursor: pointer;
}

.inline {
  display: inline-block;
}

@media (min-width: 992px) {
  .navbar {
    height: 82px;
  }
}

@media (min-width: 768px) and (max-height: 1080px) {
  .section {
    min-height: calc(100vh - 103px);
  }
}
@media (min-width: 768px) and (min-height: 1080px) {
  .section {
    min-height: calc(75vh - 103px);
  }
}
@media (max-width: 768px) {
  .section {
    min-height: 70vh;
  }
}
.btn:after {
  content: "";
  position: absolute;
  z-index: -1;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  transition: all 0.3s;
}

.btn-nav {
  border: 6px solid transparent;
}

.btn-blue {
  color: #385a80;
  border: 6px solid #385a80;
  font-family: "Graphik Bold", "Segoe UI", Roboto, "Helvetica Neue", Arial,
    sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  background: transparent;
  border-radius: 0px;
  padding-top: 15px;
  padding-left: 30px !important;
  padding-right: 30px !important;
  font-size: 30px;
}
.btn-blue:hover {
  border: 6px solid #ff9c80;
  color: #fff;
  background-color: #ff9c80;
}

.btn-promo {
  color: #385a80;
  border: 2px solid #385a80;
  font-family: "Graphik Bold", "Segoe UI", Roboto, "Helvetica Neue", Arial,
    sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  background: transparent;
  border-radius: 0px;
  font-size: 18px;
  min-height: 62px;
}
.btn-promo:hover {
  border: 2px solid #ff9c80;
  color: #fff;
  background-color: #ff9c80;
}

.btn-mar {
  margin-top: -5px;
}

.btn-navbar {
  color: #fff;
  border: 5px #385a80 solid;
  border-radius: 0px;
  margin-left: 5px;
  padding-left: 25px !important;
  padding-right: 25px !important;
}
@media (max-width: 768px) {
  .btn-navbar {
    border: none;
  }
}
.btn-navbar:hover {
  border: 5px solid #ff9c80;
  background-color: #ff9c80;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  transition: all 0.3s;
}
@media (max-width: 768px) {
  .btn-navbar {
    border: none;
  }
}

.btn-footer-bottom {
  color: #fff;
  border: 3px solid #fff;
  font-family: "Graphik Bold", "Segoe UI", Roboto, "Helvetica Neue", Arial,
    sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  background: transparent;
  border-radius: 0px;
  font-size: 20px;
  margin-left: 10px;
  padding: 13px 35px;
  cursor: pointer;
}
.btn-footer-bottom:hover {
  border: 3px solid #ff9c80;
  background-color: #ff9c80;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  transition: all 0.3s;
}

.alert-info {
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 2;
  margin: 0 auto;
  text-align: center;
  background-color: #fecbaa;
  color: #000;
  border: #fecbaa;
  padding: 40px 40px;
}

@media (min-width: 1400px) {
  .alert-info {
    height: 50px;
  }
}

@media (min-width: 992px) {
  .no-border-bottom {
    border-bottom: none !important;
  }
  .intro-section {
    background: url(./images/home_bg.png) repeat-x;
    background-size: cover;
    background-position: center bottom;
    -webkit-animation: backgroundScroll 20s linear infinite;
    animation: backgroundScroll 20s linear infinite;
  }
}
.intro-section .intro-fly h2 {
  font-size: 28px;
}

@-webkit-keyframes backgroundScroll {
  from {
    background-position: 0 0;
  }
  to {
    background-position: -400px 0;
  }
}

@keyframes backgroundScroll {
  from {
    background-position: 0 0;
  }
  to {
    background-position: -400px 0;
  }
}

.intro-section h1 {
  color: #385a80;
  font-size: 40px;
}
.intro-section .white-bg {
  background-color: #ffffe7;
  padding: 40px 40px;
  padding-left: 50px;
}
.intro-section .intro-text {
  padding-bottom: 10px;
}
.intro-section .intro-section {
  background: none;
  background-color: #fff4c0;
}
.intro-section .desktop-pad {
  padding-top: 0rem !important;
}
.intro-section .intro-fly {
  text-align: center;
  background: transparent;
}
.intro-section .intro-fly .btn {
  float: left;
  margin-top: 20px;
}
.intro-section .white-bg {
  background-color: transparent !important;
}
.intro-section h1,
.intro-section p {
  text-align: left;
}

.rules-section {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.rules-intro {
  font-size: 24px;
  text-align: center;
  padding: 20px;
}

.rule {
  font-size: 22px;
  font-family: "Graphik Bold", "Segoe UI", Roboto, "Helvetica Neue", Arial,
    sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}

#about {
  background: url("https://s3-us-west-1.amazonaws.com/assets.flyskyhi.com/images/fourcities.png")
    center right;
  background-repeat: no-repeat;
  background-color: #ffffe7;
  background-size: contain;
}
@media (max-width: 992px) {
  #about {
    background: url("https://s3-us-west-1.amazonaws.com/assets.flyskyhi.com/images/yellowstripes.png")
      center bottom repeat-x;
    background-color: #cbffec;
    background-position-y: 130%;
  }
}
@media (max-width: 768px) {
  #about {
    padding-bottom: 100px;
  }
}
.hiw {
  background-color: #fff4c0;
  background: url("https://s3-us-west-1.amazonaws.com/assets.flyskyhi.com/images/yellowclouds.png");
  background-size: cover;
}
.hiw h2 {
  color: #385a80;
}
.hiw h2.number {
  font-family: "Graphik Bold", "Segoe UI", Roboto, "Helvetica Neue", Arial,
    sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  opacity: 0.5;
}
.hiw h2.number:after {
  content: "";
  display: block;
  background: #ff9c80;
  height: 4px;
  max-width: 35px;
  margin-top: 10px;
  width: 100%;
  margin-bottom: 1rem;
}
.hiw .intro-text {
  padding-bottom: 10px;
}

#claim {
  background: url("https://s3-us-west-1.amazonaws.com/assets.flyskyhi.com/images/orangeclouds.png");
  background-color: #fecbaa;
  background-size: cover;
}

#ready {
  background: url("https://s3-us-west-1.amazonaws.com/assets.flyskyhi.com/images/greenclouds.png");
  background-color: #89fad0;
  background-size: cover;
}

@media (max-width: 768px) {
  #find-a-seat,
  #claim,
  #ready {
    padding-top: 40px;
  }
}
#discovery {
  background: url("https://s3-us-west-1.amazonaws.com/assets.flyskyhi.com/images/redstripes.png");
  background-color: #fff9ea;
  background-position: left;
  background-size: contain;
  background-repeat: no-repeat;
  padding: 20vh 0;
  /*border: 10px solid #385a80;*/
  background: #fff;
  padding-left: 45px;
}
#discovery h1,
#discovery p {
  text-align: left;
}
@media (max-width: 992px) {
  #discovery {
    background: none;
    background-color: #fff9ea;
  }
  #discovery h1,
  #discovery p {
    text-align: center;
  }
}
@media (max-width: 992px) {
  #discovery {
    border: none;
    background-color: transparent;
    text-align: center;
    padding-left: 0px;
  }
}
.discovery-icons {
  margin-top: 5rem;
}

#guidelines-header {
  background: url("https://s3-us-west-1.amazonaws.com/assets.flyskyhi.com/images/bigredstripes.png")
    left no-repeat;
  background-color: #f2faff;
  background-size: contain;
  padding: 10rem 0rem;
}
@media (max-width: 992px) {
  #guidelines-header {
    background: none;
    background-color: #fff4c0;
  }
  #guidelines-header h1,
  #guidelines-header p {
    text-align: center;
  }
}
#first p,
#second p,
#third p {
  font-family: "Graphik Medium", "Segoe UI", Roboto, "Helvetica Neue", Arial,
    sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 40px;
  line-height: 45px;
}
@media (max-width: 768px) {
  #first p,
  #second p,
  #third p {
    text-align: center;
    font-size: 25px;
    line-height: 30px;
  }
}
#first {
  background: url("https://s3-us-west-1.amazonaws.com/assets.flyskyhi.com/images/cloudsandpin.png")
    center center no-repeat;
  background-size: cover;
  background-color: #fff9ea;
}
@media (max-width: 768px) {
  #first {
    background: url("https://s3-us-west-1.amazonaws.com/assets.flyskyhi.com/images/yellowcloud.png")
      center center no-repeat;
    background-size: cover;
    background-color: #f2faff;
  }
  #first img {
    padding-top: 2rem;
  }
}
#second {
  background: url("https://s3-us-west-1.amazonaws.com/assets.flyskyhi.com/images/greenmountains.png")
    center bottom no-repeat;
  background-size: cover;
}
@media (max-width: 768px) {
  #second {
    background: url("https://s3-us-west-1.amazonaws.com/assets.flyskyhi.com/images/bluecloud.png")
      center bottom;
    background-size: cover;
    background-repeat: no-repeat;
  }
}

#third {
  background: url("https://s3-us-west-1.amazonaws.com/assets.flyskyhi.com/images/stripesandplane.png")
    center center no-repeat;
  background-size: cover;
  background-color: #cbffec;
}
@media (min-width: 1400px) {
  #third {
    background-size: contain;
  }
}
#third p {
  padding-top: 150px;
}

@media only screen and (min-device-width: 414px) and (max-device-width: 736px) and (orientation: landscape) {
  #first,
  #second,
  #third {
    padding: 40px 0px;
  }

  #third p {
    padding-top: 10px;
  }
}
#join-header {
  background: url("https://s3-us-west-1.amazonaws.com/assets.flyskyhi.com/images/bigyellowstripes.png")
    center left no-repeat;
  background-color: #cbffec;
  padding: 10rem 0rem;
}

@media (max-width: 992px) {
  #join-header {
    background: none;
    background-color: #fff9ea;
  }
}
.form {
  background: #fff4c0;
  padding: 80px 0px;
}
@media (max-width: 992px) {
  .form .form-group {
    margin-bottom: 0rem;
  }
}
@media (max-width: 492px) {
  .join-header span {
    display: block;
  }
}
.form h3 {
  font-family: "Graphik Bold", "Segoe UI", Roboto, "Helvetica Neue", Arial,
    sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  text-align: left;
  margin-top: 0px;
  font-size: 30px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 1px;
}
.form p {
  color: #3b3b3b;
  letter-spacing: 0.25px;
}
.form .input {
  position: relative;
  z-index: 1;
  display: inline-block;
  vertical-align: top;
  width: 100%;
}
.form .input__field {
  position: relative;
  display: block;
  float: right;
  padding: 0.8em;
  width: 60%;
  border: none;
  border-radius: 0;
  background: #f0f0f0;
  color: #000;
  -webkit-appearance: none;
  /* for box shadows to show on iOS */
}
.form .input__field__left {
  position: relative;
  display: inline-block;
  padding: 0.8em;
  border: none;
  border-radius: 0;
  background: #fff;
  color: #000;
  -webkit-appearance: none;
  /* for box shadows to show on iOS */
}

.form .input__field:focus {
  outline: none;
}
.form .input__label {
  display: block;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size: 14px;
}
@media (max-width: 992px) {
  .form .input_mobile {
    display: none;
  }
}
.form .input__label-content {
  position: relative;
  display: block;
  padding: 1.6em 0;
  width: 100%;
}
.form .graphic {
  position: absolute;
  top: 0;
  left: 0;
  fill: none;
}
.form .icon {
  color: #ddd;
  font-size: 150%;
}
.form .input--hideo {
  overflow: hidden;
  background: #fff;
  width: 100%;
}
.form .input--hideo--show {
  background: #fff;
  width: 100%;
}
.form .input--hideo--country {
  overflow: hidden;
  background: #fff;
  width: 100%;
}
.form .input--hideo--country select {
  width: 100%;
}
@media (max-width: 992px) {
  .form .input--hideo {
    margin-bottom: 1rem;
  }
}
.form .input__field--hideo {
  padding: 0.85em 0.85em 0.85em 3em;
  width: 100%;
  background: transparent;
  -webkit-transform: translate3d(1em, 0, 0);
  transform: translate3d(1em, 0, 0);
  -webkit-transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  height: 48px;
  padding-left: 2.5em;
}
.form .input__label--hideo {
  position: absolute;
  padding: 10px;
  width: 4em;
  height: 100%;
  height: 48px;
}
.form .input__label--hideo::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 4em;
  height: 100%;
  background: #fff;
  -webkit-transform-origin: 0% 50%;
  transform-origin: 0% 50%;
  -webkit-transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
}
.form .icon--hideo {
  color: #eee;
  -webkit-transform: scale3d(1, 1, 1);
  /* Needed for Chrome bug */
  transform: scale3d(1, 1, 1);
  -webkit-transform-origin: 0% 50%;
  transform-origin: 0% 50%;
  -webkit-transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  padding-top: 3px;
}
.form .input__label-content--hideo {
  position: absolute;
  top: 100%;
}
.form .group {
  border-bottom: dashed 5px #f8d948;
  padding: 50px 0px;
}

.form .group-top {
  border-bottom: dashed 5px #f8d948;
  padding: 20px 0px;
}
@media (max-width: 992px) {
  .form .group-top {
    padding-bottom: 50px;
  }
}
.form .group-card {
  border-bottom: dashed 5px #f8d948;
  padding: 50px 20px;
}
.form .group2 {
  padding-top: 50px;
}
.form .group1 {
  padding-bottom: 50px;
  border-bottom: dashed 5px #f8d948;
}

.form-check-inline + .form-check-inline {
  margin-left: 0rem;
}
.radioz {
  padding-right: 1rem;
}

.form .input.input--hideo.error {
  border: 1px solid red;
}

.input__label.input_mobile.error {
  color: red;
}

.error-box {
  padding-left: 15px;
}

.error-list {
  border-radius: 20px;
  margin: 0 auto;
}
.error-list li {
  padding: 5px;
  color: red;
}
.error-list li:last-child {
  margin-bottom: 10px;
}

.success {
  background: url("https://s3-us-west-1.amazonaws.com/assets.flyskyhi.com/images/biggreenmountains.png");
}

.error {
  background: url("https://s3-us-west-1.amazonaws.com/assets.flyskyhi.com/images/bigredmountains.png");
}

.downarrow {
  position: absolute;
  left: 44%;
  margin-top: 10px;
}

.left {
  background-image: url("https://s3-us-west-1.amazonaws.com/assets.flyskyhi.com/images/manyredstripes.png");
  background-position: bottom left;
  background-repeat: repeat-y;
  background-color: #fff3bf;
  width: 50%;
  float: left;
}

.right {
  background-color: #ffffe7;
  background-image: url("https://s3-us-west-1.amazonaws.com/assets.flyskyhi.com/images/skyhi-so-fly_orange%402x.png");
  background-size: 300px;
  background-position-x: 100%;
  background-position-y: -25%;
  background-repeat: no-repeat;
  width: 50%;
  float: left;
}

.earlyaccess {
  display: flex;
  justify-content: center;
  align-items: center;
}

.earlyaccess-left {
  background-color: #fff3bf;
  padding: 80px;
}

.earlyaccess-right {
  background-color: #ffffe7;
}

.white {
  background-color: #fff;
  padding: 60px 80px;
}
@media (max-width: 992px) {
  .white {
    padding: 20px;
  }
}
@media (max-width: 1100px) {
  .left,
  .right {
    width: 50%;
  }
}
@media (max-width: 992px) {
  .left,
  .right {
    width: 100%;
    text-align: center;
    padding: 65px 0px;
  }

  .left {
    background-image: none;
  }
}

.left-welcome {
  background: url("./images/horizontal-lines_yellow@2x.png") center center
    no-repeat;
  background-color: #fff3bf;
  width: 60%;
  float: left;
}

.right-welcome {
  width: 40%;
  background: url("./images/dark-blue_light-blue_bg@2x.png") center center
    no-repeat;
  background-size: cover;
  float: left;
  max-height: 875px;
}

@media (max-width: 992px) {
  .left-welcome,
  .right-welcome,
  .pad-65 {
    padding: 65px 0px;
  }
}

.up-to-5 {
  background: url("./images/icn_pale-yellow-vert-bars@2x.png") bottom left
    repeat-y;
  background-color: #fecbaa;
}
.video {
  background-color: #fecbaa;
  padding: 100px 0px;
}
.left-miles {
  background: url("./images/skyhisofly_orange_half@2x.png") left top no-repeat;
  background-color: #fff4c0;
  width: 50%;
  float: left;
}
.left-miles .left-padding {
  padding: 0px 150px;
}

.right-miles {
  background: url("./images/zigzag_green.svg") bottom center repeat-x;
  background-color: #cbffec;
  width: 50%;
  float: left;
}

.map {
  background: url("./images/vert-bars_green.svg") center left repeat-y;
  background-position-x: -20px;
  background-color: #f1fcfe;
  padding: 100px 0px;
}
.map h4 {
  margin-top: 1rem;
}

.title {
  padding: 20px 0px;
}

.border-map {
  border-top: 3px dashed #b0ebfb;
  border-bottom: 3px dashed #b0ebfb;
}

.dropdown {
  border-right: 3px dashed #b0ebfb;
  margin-bottom: 20px;
}

.departure {
  font-family: "Graphik Bold", "Segoe UI", Roboto, "Helvetica Neue", Arial,
    sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  color: #385a80;
  font-size: 30px;
  margin-top: 1rem;
}

select {
  color: #ff9c80;
}

.left-sofly {
  background-color: #fff4c0;
  width: 60%;
  float: left;
}

.right-sofly {
  background: url("./images/blue-white_bg@2x.png") center center no-repeat;
  background-color: #b0ecff;
  width: 40%;
  float: left;
}

.complete {
  padding: 40px 0px;
}

.invite-code {
  background-color: #fecbaa;
  padding: 50px 0px;
}

.code {
  background-color: #fff;
  border-radius: 4px;
  width: 20%;
  margin: 0 auto;
  padding: 20px 0px;
}
.flex-list {
  display: flex;
  flex-wrap: wrap;
  color: #ff9c80;
  padding-top: 20px;
}
.flex-list li {
  font-size: 2em;
  font-family: "Graphik Bold", "Segoe UI", Roboto, "Helvetica Neue", Arial,
    sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  padding-top: 5px;
  padding-right: 10px;
}
.flex-list li:after {
  content: "";
  display: inline-block;
  width: 10px;
  height: 10px;
  margin-left: 5px;
  margin-bottom: 5px;
  -moz-border-radius: 7.5px;
  -webkit-border-radius: 7.5px;
  border-radius: 7.5px;
  background-color: #ff9c80;
}

h2.sal {
  color: #ff9c80;
}

h2.no-background {
  position: relative;
  overflow: hidden;
  text-align: center;
  font-size: 3em;
  color: #ffd5b5;
}
h2.no-background span {
  display: inline-block;
  vertical-align: baseline;
  zoom: 1;
  *display: inline;
  *vertical-align: auto;
  position: relative;
  padding: 0 20px;
  padding: 0 15px;
}
h2.no-background span:before,
h2.no-background span:after {
  content: "";
  display: block;
  width: 1000px;
  position: absolute;
  top: 0.53em;
  border-top: 1px solid #ffd5b5;
}
h2.no-background span:before {
  right: 100%;
}
h2.no-background span:after {
  left: 100%;
}

.white-box {
  background-color: #fff;
  padding: 40px;
  border-radius: 8px;
}
.white-box p {
  font-size: 0.9rem;
}

.pill-container {
  display: flex;
  justify-content: center;
}

.nav-pill li {
  display: inline;
}
.nav-pill {
  background-color: #fff;
  font-size: 1.5rem;
  border-radius: 40px;
  font-family: "Graphik Bold", "Segoe UI", Roboto, "Helvetica Neue", Arial,
    sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  display: flex;
  width: 100%;
}
.nav-pill .pill {
  padding: 10px 30px;
  border-radius: 40px;
  color: #b8efff;
  text-align: center;
  flex: 1;
}
@media (max-width: 515px) {
  .nav-pill {
    border-radius: 0px;
  }
  .nav-pill .pill {
    font-size: 24px;
    padding: 10px 20px;
    border-radius: 0px;
  }
}
.nav-pill .selected {
  background-color: #385a80;
  color: #fff !important;
}

h4.blue {
  color: #375a7f;
  background: transparent;
}

@media (max-width: 1200px) {
  .left-welcome,
  .right-welcome,
  .left-miles,
  .right-miles,
  .left-sofly,
  .right-sofly {
    width: 100%;
  }
  .left-welcome .section,
  .right-welcome .section,
  .left-miles .section,
  .right-miles .section,
  .left-sofly .section,
  .right-sofly .section {
    min-height: calc(50vh - 103px);
  }
  .left-welcome .section .white,
  .right-welcome .section .white,
  .left-miles .section .white,
  .right-miles .section .white,
  .left-sofly .section .white,
  .right-sofly .section .white {
    margin-top: 0px;
  }
  .left-welcome .arrowdown,
  .right-welcome .arrowdown,
  .left-miles .arrowdown,
  .right-miles .arrowdown,
  .left-sofly .arrowdown,
  .right-sofly .arrowdown {
    display: none;
  }
  .left-welcome .img-pad img,
  .right-welcome .img-pad img,
  .left-miles .img-pad img,
  .right-miles .img-pad img,
  .left-sofly .img-pad img,
  .right-sofly .img-pad img {
    max-width: 100%;
    height: auto;
  }

  .left-miles .left-padding {
    padding: 0px 15px;
  }

  @media (min-width: 768px) {
    .hidden-md-up {
      display: none !important;
    }
  }
}
.navbar {
  padding: 0;
  z-index: 10;
}

.only-mobile {
  display: none;
}

.navbar-light .navbar-nav .nav-link {
  font-family: "Graphik Bold", "Segoe UI", Roboto, "Helvetica Neue", Arial,
    sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  color: #385a80;
  font-size: 18px;
  padding: 0.5rem;
}

.navbar-light .navbar-nav .nav-link:focus {
  color: #385a80;
}

.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.active,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .show > .nav-link {
  color: #385a80;
}
@media (max-width: 768px) {
  .navbar-light .navbar-nav .nav-link {
    text-align: center;
  }
}
.navbar-light .navbar-nav .nav-link:hover {
  color: #ff9c80;
}

.navbar-light .navbar-nav .nav-link.active {
  color: #ff9c80;
}

.navbar-toggler {
  margin-top: 25px;
  z-index: 4;
}

.navbar-light span.navbar-toggler-icon {
  background-image: url("./images/menu_bt.svg");
  border: transparent;
}

.navbar .navbar-toggler .icon-bar {
  background: red !important;
}

.nav-row {
  background-color: #ffffe9;
  font-family: "Graphik Bold", "Segoe UI", Roboto, "Helvetica Neue", Arial,
    sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}
.nav-row .subnav-link {
  padding: 20px;
  font-size: 20px;
}
.nav-row .subnav-link a {
  color: #385a80;
}

.nav-row a {
  text-decoration: none;
}

.nav-row a:hover {
  color: #ff9c80;
}

.progress_box {
  padding: 40px 0px;
  background-color: #ffffe9;
  border-radius: 8px;
}
.progress_box .progress-link {
  display: block;
  text-align: center;
}
.progress_box .progress-link a {
  color: #122e49;
  font-weight: bold;
}

.line {
  margin: 0px auto;
  width: 0%;
  height: 60px;
  border: 1px solid #fff4c0;
  margin-bottom: 4px;
}

.circle {
  padding-top: 15px;
  border-radius: 50%;
  width: 15px;
  height: 15px;
  background-color: #fff4c0;
  margin: 0px auto;
}

.active-progress {
  background-color: #ffd5b5 !important;
}

.nobottom {
  margin: 0px;
}

#gender label {
  float: left;
  width: 140px;
  margin-right: 4px;
  background-color: #fff;
  border-radius: 4px;
  border: 1px solid #fff;
  overflow: auto;
}
#gender label span {
  text-align: center;
  padding: 10px 0px;
  display: block;
}
#gender input {
  position: absolute;
  display: none;
}
#gender input:checked + span {
  background-color: #ff9c80;
  color: #fff;
}
#gender .yellow {
  background-color: #ffcc00;
  color: #333;
}

#error-left,
#error-right {
  min-height: 100vh;
}
@media (max-width: 992px) {
  #error-left,
  #error-right {
    display: none;
  }
}
#error-left {
  width: 40%;
  background-color: #fffdf9;
  float: left;
}

#error-right {
  float: right;
  width: 60%;
  background: url("https://s3-us-west-1.amazonaws.com/assets.flyskyhi.com/images/blueverticalstripes.png")
    center center no-repeat;
  background-size: cover;
}

#error-section {
  position: absolute;
  width: 80%;
  height: 50%;
  margin: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 0px 30px;
}
@media (max-width: 992px) {
  #error-section {
    position: inherit;
    top: 50vh;
    border: none;
    padding-top: 15vh;
  }
}
footer .input {
  position: relative;
  z-index: 1;
  display: inline-block;
  vertical-align: top;
  width: 100%;
}
footer .input__field {
  position: relative;
  display: block;
  float: right;
  padding: 0.8em;
  width: 60%;
  border: none;
  border-radius: 0;
  background: #f0f0f0;
  color: #aaa;
  -webkit-appearance: none;
}

footer .input__field:focus {
  outline: none;
}
footer .input--hideo {
  overflow: hidden;
  background: #fff;
  width: 60%;
}

footer .input__field--hideo {
  padding: 0;
  width: 100%;
  background: transparent;
  -webkit-transform: translate3d(1em, 0, 0);
  transform: translate3d(1em, 0, 0);
  -webkit-transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  height: 60px;
  padding-left: 2.5e;
}
footer .flex-center {
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 10px 0px;
}
@media (max-width: 992px) {
  footer .flex-center {
    align-items: center;
  }
}
@media (max-width: 992px) {
  footer .flex-center-mobile {
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 10px 0px;
  }
  @media (max-width: 992px) {
    footer .flex-center-mobile {
      align-items: center;
    }
  }
}
footer p.apply {
  display: inline;
  padding-right: 20px;
}
footer .btn-footer {
  display: inline;
}
@media (max-width: 992px) {
  footer .img-center {
    margin-right: auto !important;
    margin-left: auto !important;
    display: block !important;
  }
}
footer .header {
  font-family: "Graphik Bold", "Segoe UI", Roboto, "Helvetica Neue", Arial,
    sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  color: #385a80;
  font-size: 25px;
  text-transform: uppercase;
  letter-spacing: 1px;
}
footer .footer-top {
  background-color: #f0fcff;
}
footer .footer-top .upper {
  padding: 60px 0px;
}
@media (min-width: 768px) {
  footer .footer-top .upper ul.footer-nav {
    columns: 2;
    -webkit-columns: 2;
    -moz-columns: 2;
  }
}
footer .footer-top .upper .footer-nav-link {
  font-family: "Graphik Bold", "Segoe UI", Roboto, "Helvetica Neue", Arial,
    sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  color: #385a80;
  font-size: 25px;
  text-transform: uppercase;
  letter-spacing: 1px;
}
footer .footer-top .upper .footer-nav-link:hover {
  color: #ff9c80;
}
footer .footer-top .upper .footer-nav-header {
  font-family: "Graphik Bold", "Segoe UI", Roboto, "Helvetica Neue", Arial,
    sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  color: #385a80;
  font-size: 25px;
  text-transform: uppercase;
  letter-spacing: 1px;
  text-decoration: underline;
}
footer .footer-top .upper .footer-nav-header-download {
  font-family: "Graphik Bold", "Segoe UI", Roboto, "Helvetica Neue", Arial,
    sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  color: #385a80;
  font-size: 25px;
  text-transform: uppercase;
  letter-spacing: 1px;
  text-decoration: none;
  padding-top: 4px;
}

footer .footer-top .upper .footer-nav-item {
  font-family: "Graphik Bold", "Segoe UI", Roboto, "Helvetica Neue", Arial,
    sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  color: #385a80;
  font-size: 18px;
  text-transform: uppercase;
  letter-spacing: 1px;
  display: block;
}
footer .footer-top .upper .footer-nav-item {
  padding-top: 4px;
}

footer .footer-top .upper .social a {
  font-family: "Graphik Medium", "Segoe UI", Roboto, "Helvetica Neue", Arial,
    sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  color: #385a80;
  text-transform: uppercase;
}
footer .footer-top .upper .social a:hover {
  color: #ff9c80;
}
footer .footer-bottom {
  background-color: #385a80;
}
footer .footer-bottom .lower {
  padding: 40px 0px;
}
footer .footer-bottom .lower p {
  color: #fff;
}
footer .footer-bottom .lower .subtext {
  font-size: 14px;
}
footer .footer-top.top-border {
  border-top: 4px solid #baefff;
}
footer .footer-top .contact-item {
  font-family: "Graphik Medium", "Segoe UI", Roboto, "Helvetica Neue", Arial,
    sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  color: #385a80;
  text-transform: uppercase;
  text-align: center;
  padding: 5px 15px;
  border-right: 4px solid #baefff;
}

footer .footer-top .contact-item.last {
  border-right: none;
}

@media (max-width: 992px) {
  .subtext {
    margin-bottom: 1rem;
  }
  footer .footer-top .contact-item {
    display: block;
  }
}
nav {
  background-color: #fff;
}
@media (min-width: 768px) {
  nav .hidden-md-up {
    display: none !important;
  }
}
/* @media (max-width: 767px) {
  nav .navbar-toggleable-sm {
    clear: both;
  }
}
@media (min-width: 992px) {
  nav .navbar-toggleable .navbar-toggler {
    display: none !important;
  }
} */

.navbar {
  padding: 0;
}
.navbar-toggleable {
  padding: 5px 0px;
}

.navbar-light .navbar-nav .nav-link {
  font-family: "Graphik Bold", "Segoe UI", Roboto, "Helvetica Neue", Arial,
    sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  color: #385a80;
  font-size: 18px;
  padding: 0.5rem;
}
@media (max-width: 768px) {
  .navbar-light .navbar-nav .nav-link {
    text-align: center;
  }
}
.navbar-light .navbar-nav .nav-link:hover {
  color: #ff9c80;
}

.navbar-light .navbar-nav .nav-link.active {
  color: #ff9c80;
}

.navbar-light .navbar-nav .btn-navbar:hover {
  color: #fff !important;
}

.navbar-toggler {
  margin-top: 25px;
}

.navbar .navbar-toggler .icon-bar {
  background: red !important;
}

#discovery {
  background: url("https://s3-us-west-1.amazonaws.com/assets.flyskyhi.com/images/slantedredstripes.png");
  background-color: #fff9ea;
  background-position: left;
  background-size: contain;
  background-repeat: no-repeat;
  padding: 20vh 0;
}
#discovery h1,
#discovery p {
  text-align: left;
}
@media (max-width: 992px) {
  #discovery {
    background: none;
    background-color: #fff9ea;
  }
  #discovery h1,
  #discovery p {
    text-align: center;
  }
}
.discovery-box {
  border: 10px solid #385a80;
  background: #fff;
  padding-left: 45px;
}
@media (max-width: 992px) {
  .discovery-box {
    border: none;
    background-color: transparent;
    text-align: center;
    padding-left: 0px;
  }
}
.discovery-icons {
  margin-top: 5rem;
}

#terms-page,
#privacy-page {
  padding-top: 7vh;
  background: #fff4c0;
}
#terms-page .hero,
#privacy-page .hero {
  background: url("https://s3-us-west-1.amazonaws.com/assets.flyskyhi.com/images/whitestripes.png")
      center left no-repeat,
    url("https://s3-us-west-1.amazonaws.com/assets.flyskyhi.com/images/horizontalyellowclouds.png")
      center center no-repeat;
  background-size: contain, cover;
}
#terms-page .hero h2,
#privacy-page .hero h2 {
  padding: 100px;
  text-align: center;
}

.terms_of_service,
.privacy_policy {
  padding: 30px 0px;
}
.terms_of_service p,
.terms_of_service span,
.terms_of_service ol,
.terms_of_service ul,
.terms_of_service li,
.privacy_policy p,
.privacy_policy span,
.privacy_policy ol,
.privacy_policy ul,
.privacy_policy li {
  color: #000;
  font-family: "Helvetica Nue", sans-serif;
  letter-spacing: normal;
  line-height: 20px;
}
.terms_of_service p span,
.privacy_policy p span {
  font-size: 14px;
}
.terms_of_service p span strong,
.privacy_policy p span strong {
  font-size: 16px;
  font-weight: bold;
}
.terms_of_service #response,
.privacy_policy #response {
  padding-top: 30vh;
}
.terms_of_service #response .status_header,
.privacy_policy #response .status_header {
  padding-top: 20px;
}
.terms_of_service #response .status,
.privacy_policy #response .status {
  color: #3b3b3b;
  letter-spacing: 0.25px;
}

a[href^="tel:"] {
  color: #385a80;
  text-decoration: none;
}

a[href^="mailto:"] {
  color: #385a80;
  text-decoration: none;
}
@media (max-width: 992px) {
  .desktop-pad {
    padding-top: 0rem !important;
  }

  .intro-fly {
    text-align: center;
    background: transparent;
  }

  .intro-fly.btn {
    float: left;
  }

  .white-bg {
    background-color: transparent !important;
  }

  h1,
  p {
    text-align: left;
  }
}

.loop .news {
  display: block !important;
}

.error-button {
  opacity: 0.65;
  cursor: not-allowed;
}

@media (max-width: 575px) {
  .safari-center {
    text-align: center;
  }
}

.form-right-space {
  margin-right: 20px;
}
.form-40 {
  width: 40%;
}
.form-60 {
  width: 60%;
}
.form-100 {
  width: 100%;
}
.card-group {
  padding: 0px 20px !important;
}
.input-wrap {
  display: flex;
}
.react-datepicker__input-container input {
  border: transparent;
  background: #fff;
  width: 100%;
  height: 48px;
  padding: 0.8em;
}
.rw-widget {
  border: none !important;
  border-radius: 0px !important;
}
.rw-input {
  padding: 0.9em !important;
  height: auto !important;
}
.rw-widget > .rw-select {
  border-left: none !important;
}
.rw-multiselect,
.rw-combobox input.rw-input,
.rw-datetimepicker input.rw-input,
.rw-numberpicker input.rw-input {
  box-shadow: none !important;
}
.redux-error-form {
  position: absolute;
  right: 20%;
  top: 20%;
  color: red;
}

.input-country select {
  width: 100%;
  height: 48px;
  border: transparent;
  background: #fff;
  color: inherit;
}

.rw-widget.rw-state-focus .rw-popup-container {
  position: absolute;
  z-index: 20000 !important;
}

.rw-popup {
  position: absolute;
  z-index: 30000 !important;
}

.nav-item.dropdown {
  margin-bottom: 0px;
  border-right: none;
  bottom: -6px;
}

.z-2 {
  z-index: 2 !important;
}

.z-3 {
  z-index: 3 !important;
}

.graph-row {
  display: flex;
  flex-direction: row;
  padding: 10px 0px;
}

.graph-row img {
  margin-right: 10px;
  width: 66px;
}

.graph-text-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.right-info h4 {
  margin-bottom: 0px;
}
.right-info p {
  line-height: initial;
}
.Aligner {
  display: flex;
  align-items: center;
  justify-content: center;
}

.Aligner-item {
  max-width: 65%;
  padding: 0px 20px;
}

.Aligner-item-img {
  width: 30%;
  padding-left: 5%;
}
.disclaimer-checkbox {
  padding-left: 5px;
  color: #385a80;
}
.disclaimers label {
  display: block;
  /*float: left;*/
  margin-left: 18px;
}
.disclaimers label input[type="checkbox"] {
  margin-left: -18px;
}
.checkboxerror {
  border: 1px dashed red;
  margin: 20px 0px;
}
.mobile-rules {
  padding-top: 40px;
}
.form-white {
  background-color: #fff;
  padding: 50px 20px;
}
.img-shrink {
  width: 25%;
  margin-bottom: -60px;
}
.form-white h1 {
  text-align: center;
}

.section-signin {
  min-height: calc(85vh - 103px);
}

@media (max-width: 992px) {
  .section-signin {
    min-height: 50vh;
  }
}

.center-h1 h1 {
  text-align: center;
}
.active-subnav {
  color: #ff9c80;
}
@media (max-width: 992px) {
  .date-picker {
    margin-bottom: 20px;
  }
}
.disclaimer-footer {
  padding: 20px 0px;
}
.disclaimer-footer .lead {
  font-size: 14px;
  font-weight: bold;
  line-height: initial;
}
.disclaimer-footer p {
  font-size: 14px;
  line-height: initial;
}
.travelinfo p {
  font-family: "Graphik Bold", "Segoe UI", Roboto, "Helvetica Neue", Arial,
    sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  line-height: 20px;
}
.travelinfo h3 {
  font-size: 35px;
}
@media (max-width: 992px) {
  .travelinfo h3 {
    font-size: 30px;
  }
  .disclaimers {
    padding-top: 30px;
  }
}

.travelinfo h4 {
  color: #385a80;
  font-family: "Graphik Bold", "Segoe UI", Roboto, "Helvetica Neue", Arial,
    sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 22px !important;
}
.btn-secondary {
  background: transparent;
  border: transparent;
}
.btn-secondary:hover,
.btn-secondary:focus,
.btn-secondary:visited,
.btn-secondary:active,
.btn-secondary:focus {
  background: transparent;
  border: transparent;
  box-shadow: none;
}

a.btn-mar.active {
  border: 5px #ff9c80 solid;
}

.outline {
  border: 2px solid #ffd5b5;
  min-width: 215px;
}

.form.form-white {
  background-color: #fff;
}

li.last:after {
  background-color: transparent;
}

h4.sb {
  font-family: "Graphik Bold", "Segoe UI", Roboto, "Helvetica Neue", Arial,
    sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}
img.width-30 {
  width: 30%;
}
img.width-50 {
  width: 50%;
}
h4.dark {
  color: #385a80;
}
.form-group {
  margin-bottom: 1.5rem;
}
h2.movedown {
  margin-bottom: 1rem !important;
}
.dropdown-toggle {
  cursor: pointer;
}

@media (max-width: 992px) {
  .left-miles .left-padding {
    padding: 65px 15px;
  }
  .left-miles {
    background: #fff4c0;
  }
  .map {
    background: #f1fcfe;
  }
  h2.mobile-sb {
    font-family: "Graphik Bold", "Segoe UI", Roboto, "Helvetica Neue", Arial,
      sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-size: 40px;
  }
  .btn-mobile-pad {
    margin-top: 25px;
  }
}
.dropdown-toggle::after {
  bottom: 65%;
}
.btn-mar.btn-navbar.nav-link.dropdown-toggle.btn.btn-secondary:hover {
  color: #ff9c80 !important;
}

.pb-container {
  display: inline-block;
  width: 100%;
}
.pb-container .pb-button {
  background: transparent;
  border: 6px solid #385a80;
  border-radius: 0px;
  color: #385a80;
  cursor: pointer;
  padding: 10px 30px;
  text-decoration: none;
  text-align: center;
  width: 50%;
  -webkit-tap-highlight-color: transparent;
  outline: none;
  transition: background-color 0.3s, width 0.3s, border-width 0.3s,
    border-color 0.3s, border-radius 0.3s;
  font-family: "Graphik Bold", "Segoe UI", Roboto, "Helvetica Neue", Arial,
    sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}

.pb-container .pb-button:hover {
  border: 6px solid #ff9c80;
  color: #fff;
  background-color: #ff9c80;
}

.pb-container .pb-button span {
  display: inherit;
  transition: opacity 0.3s 0.1s;
  font-size: 1.5em;
  font-weight: 100;
}
.pb-container .pb-button svg {
  height: 54px;
  width: 54px;
  position: absolute;
  transform: translate(-50%, -50%);
  pointer-events: none;
}
.pb-container .pb-button svg path {
  opacity: 0;
  fill: none;
}
.pb-container .pb-button svg.pb-progress-circle {
  animation: spin 0.9s infinite cubic-bezier(0.085, 0.26, 0.935, 0.71);
}
.pb-container .pb-button svg.pb-progress-circle path {
  stroke: currentColor;
  stroke-width: 5;
}
.pb-container .pb-button svg.pb-checkmark path,
.pb-container .pb-button svg.pb-cross path {
  stroke: #fff;
  stroke-linecap: round;
  stroke-width: 4;
}
.pb-container.disabled .pb-button {
  cursor: not-allowed;
}
.pb-container.loading .pb-button {
  border-width: 6.5px;
  border-color: transparent;
  border-radius: 27px;
  cursor: wait;
  background-color: transparent;
  padding: 0;
}
.pb-container.loading .pb-button span {
  transition: all 0.15s;
  opacity: 0;
  display: none;
}
.pb-container.loading .pb-button .pb-progress-circle > path {
  transition: opacity 0.15s 0.3s;
  opacity: 1;
}
.pb-container.success,
.pb-container.error {
  background: none;
}
.pb-container.success .pb-button {
  border-color: #a0d468;
  background-color: #a0d468;
  padding: 15px 30px;
}
.pb-container.success .pb-button span {
  transition: all 0.15s;
  opacity: 0;
  display: none;
}
.pb-container.success .pb-button .pb-checkmark > path {
  opacity: 1;
}
.pb-container.error .pb-button {
  border-color: #ed5565;
  background-color: #ed5565;
  padding: 15px 30px;
}
.pb-container.error .pb-button span {
  transition: all 0.15s;
  opacity: 0;
  display: none;
}
.pb-container.error .pb-button .pb-cross > path {
  opacity: 1;
}
@keyframes spin {
  from {
    transform: translate(-50%, -50%) rotate(0deg);
    transform-origin: center center;
  }
  to {
    transform: translate(-50%, -50%) rotate(360deg);
    transform-origin: center center;
  }
}
.appdownload {
  padding: 100px 0px;
  background: url("./images/home_bg.png") repeat-x;
  background-size: cover;
  height: 80vh;
}
.appdownload-content {
  padding-top: 10vh;
}
.whiteboxnopad {
  background-color: #fff;
  color: #375a7f;
}
.pad-10 {
  padding: 30px;
}
.whiteboxnopad hr {
  margin-top: 0px;
  margin-bottom: 0px;
}
.whiteboxnopad h3 {
  margin-bottom: 0px;
  color: #375a7f;
}
.whiteboxnopad h4 {
  margin-bottom: 0px;
  color: #375a7f;
}
.whiteboxnopad p {
  color: #375a7f;
}
a.active-subnav:focus {
  color: #ff9c80;
}
a.active-subnav:hover {
  color: #ff9c80;
}
.warning-pad {
  padding: 15px 10px;
}
.hideme {
  display: none !important;
}
.baggagepage {
  background: #fff4c0;
  padding: 80px 0px;
}
.baggageflex {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.baggageinfobox {
  background: #fff;
  width: 32%;
  border-radius: 4px;
  margin: 10px 0px;
  padding: 10px;
  text-align: center;
}
.baggageinfobox a {
  text-decoration: none;
  color: #385a80;
}
.airlinelogo {
  width: 50%;
}
.dobselect {
  background: #fff;
  display: inline;
  color: initial;
}
.no-margin {
  margin: 0px;
}
.left-5 {
  padding-left: 15px;
}
.flex-grow-1 {
  flex-grow: 1;
  width: 100%;
}
.width-20 {
  width: 20%;
}
.input-year {
  height: 48px;
  width: 100px;
  padding-left: 10px;
  border: 0px;
}

@media (max-width: 515px) {
  .width-20 {
    width: 50%;
  }
}

.loader {
  margin: 100px auto;
  font-size: 25px;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  position: relative;
  text-indent: -9999em;
  -webkit-animation: load5 1.1s infinite ease;
  animation: load5 1.1s infinite ease;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}
@-webkit-keyframes load5 {
  0%,
  100% {
    box-shadow: 0em -2.6em 0em 0em #ffffff,
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2),
      2.5em 0em 0 0em rgba(255, 255, 255, 0.2),
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2),
      0em 2.5em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2),
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.5),
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.7);
  }
  12.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.7),
      1.8em -1.8em 0 0em #ffffff, 2.5em 0em 0 0em rgba(255, 255, 255, 0.2),
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2),
      0em 2.5em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2),
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.5);
  }
  25% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.5),
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.7), 2.5em 0em 0 0em #ffffff,
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2),
      0em 2.5em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2),
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  37.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2),
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.5),
      2.5em 0em 0 0em rgba(255, 255, 255, 0.7), 1.75em 1.75em 0 0em #ffffff,
      0em 2.5em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2),
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  50% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2),
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2),
      2.5em 0em 0 0em rgba(255, 255, 255, 0.5),
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.7), 0em 2.5em 0 0em #ffffff,
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2),
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  62.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2),
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2),
      2.5em 0em 0 0em rgba(255, 255, 255, 0.2),
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.5),
      0em 2.5em 0 0em rgba(255, 255, 255, 0.7), -1.8em 1.8em 0 0em #ffffff,
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  75% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2),
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2),
      2.5em 0em 0 0em rgba(255, 255, 255, 0.2),
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2),
      0em 2.5em 0 0em rgba(255, 255, 255, 0.5),
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.7), -2.6em 0em 0 0em #ffffff,
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  87.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2),
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2),
      2.5em 0em 0 0em rgba(255, 255, 255, 0.2),
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2),
      0em 2.5em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.5),
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.7), -1.8em -1.8em 0 0em #ffffff;
  }
}
@keyframes load5 {
  0%,
  100% {
    box-shadow: 0em -2.6em 0em 0em #ffffff,
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2),
      2.5em 0em 0 0em rgba(255, 255, 255, 0.2),
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2),
      0em 2.5em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2),
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.5),
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.7);
  }
  12.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.7),
      1.8em -1.8em 0 0em #ffffff, 2.5em 0em 0 0em rgba(255, 255, 255, 0.2),
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2),
      0em 2.5em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2),
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.5);
  }
  25% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.5),
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.7), 2.5em 0em 0 0em #ffffff,
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2),
      0em 2.5em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2),
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  37.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2),
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.5),
      2.5em 0em 0 0em rgba(255, 255, 255, 0.7), 1.75em 1.75em 0 0em #ffffff,
      0em 2.5em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2),
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  50% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2),
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2),
      2.5em 0em 0 0em rgba(255, 255, 255, 0.5),
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.7), 0em 2.5em 0 0em #ffffff,
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2),
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  62.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2),
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2),
      2.5em 0em 0 0em rgba(255, 255, 255, 0.2),
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.5),
      0em 2.5em 0 0em rgba(255, 255, 255, 0.7), -1.8em 1.8em 0 0em #ffffff,
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  75% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2),
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2),
      2.5em 0em 0 0em rgba(255, 255, 255, 0.2),
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2),
      0em 2.5em 0 0em rgba(255, 255, 255, 0.5),
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.7), -2.6em 0em 0 0em #ffffff,
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  87.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2),
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2),
      2.5em 0em 0 0em rgba(255, 255, 255, 0.2),
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2),
      0em 2.5em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.5),
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.7), -1.8em -1.8em 0 0em #ffffff;
  }
}
.crossthrough {
  text-decoration: line-through;
}
.press-slider-section {
  padding: 260px 0px;
  background: #f0fcff;
}
.press-slider {
  padding: 40px 0px;
  border: 6px solid #385a80;
  margin-top: -30px;
}

@media (max-width: 992px) {
  .press-slider-section {
    padding: 80px 0px;
  }
}
.press-slider h3 {
  font-size: 24px;
  color: #385a80;
  padding-top: 60px;
}
.press-header {
  display: inline;
  background-color: #f0fcff;
  padding: 0px 20px;
}
.press-slider-content {
  padding: 60px 80px;
}
.slick-next:before,
.slick-prev:before {
  font-size: 60px;
}
.slick-next,
.slick-prev {
  width: 60px;
  height: 55px;
  z-index: 1;
  background: #f0fcff;
}

.slick-next:before,
.slick-prev:before {
  color: #385a80;
}
.smaller-p {
  text-align: left;
  font-size: 16px;
  padding: 10px 20px;
}

.navlogo {
  width: 75%;
}

.arrow {
  text-align: center;
}

.arrowdown {
  margin-top: -70px;
  padding-bottom: 10px;
}
.origin {
  background: rgb(255, 244, 191);
  padding: 40px 0px;
  text-align: center;
  font-weight: bold;
  font-size: 24px;
  color: #385a80;
}
.origin .dropdown {
  border-right: none;
  display: inline;
  margin-left: 10px;
  font-size: 28px;
  border-bottom: 4px solid #385a80;
}
/* .origin .dropdown:after {
  background-color: #385a80;
  bottom: -10px;
  content: "";
  display: block;
  height: 10px;
  position: relative;
  width: 20%;
  text-align: center;
  left: 40%;
} */

.cities-select-section {
  background: rgb(255, 255, 231);
}
.citiesflex {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}
.citybox {
  min-width: 25%;
  flex: 1;
  border-radius: 4px;
  padding: 20px 0px;
  padding-bottom: 0px;
  text-align: center;
  border: dashed 5px rgb(255, 244, 191);
  border-top: none;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.city-text-flex {
  display: flex;
  flex-direction: column;
}

.citybox h4 {
  margin-bottom: 0px;
}

@media (min-width: 992px) {
  .citybox:nth-child(4n + 1),
  .citybox:nth-child(2n + 1),
  .citybox:nth-child(4n + 2) {
    border-right: none;
  }
  .citybox:nth-child(-n + 4) {
    border-top: dashed 5px rgb(255, 244, 191);
    max-width: 282.5px;
  }
  .citybox {
    max-width: 277.5px;
  }
  .citybox:nth-last-child(1):nth-child(odd) {
    border-right: dashed 5px rgb(255, 244, 191);
    max-width: 282.5px;
  }
  .citybox:nth-child(4n + 2):last-child {
    border-right: dashed 5px rgb(255, 244, 191);
  }
  .flex-airline-logo {
    width: 25%;
    margin: 10px 0px;
  }
}

@media (max-width: 992px) and (min-width: 769px) {
  .citybox {
    min-width: 50%;
    justify-content: space-between;
  }
  .citybox:nth-child(odd) {
    border-right: none;
  }
  .citybox:nth-child(-n + 2) {
    border-top: dashed 5px rgb(255, 244, 191);
  }
}

@media (max-width: 768px) {
  /*   .citiesflex {
    display: block;
  } */
  .citybox {
    min-width: 100%;
  }
  .citybox:first-child {
    border-top: dashed 5px rgb(255, 244, 191);
  }
}
.rulesflex {
  display: flex;
  justify-content: space-between;
}
.rulesblock {
  width: 22%;
  border-radius: 4px;
  flex: 1;
  padding: 20px 0px;
  margin: 0px 10px;
  text-align: center;
}
.rules-intro-text {
  padding: 10px 120px;
  padding-top: 0px;
}
.rules-intro-text p {
  padding: 5px 0px;
}

.rulesblock p {
  padding: 0px 15px;
}

.rulesblocks {
  padding: 40px 0px;
  /* background: #ffffe7; */
}
.aligner {
  display: flex;
  align-items: center;
  justify-content: center;
}
/* .rulebox1 {
  background-color: #013a6b;
  background-image: -webkit-linear-gradient(30deg, #013a6b 50%, #004e95 50%);
}
 */

.rulebox1 {
  height: 380px;
  background-image: linear-gradient(#ff9c80, #ffb9a6);
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 calc(100% - 5vw));
  /* change the calc height to a percentage height to get alternate responsive behavior*/
}
.rulebox1 p,
.rulebox4 p {
  color: #fff;
}

.rulebox2 {
  margin-top: -5vw;
  background-image: linear-gradient(135deg, #f0fcff, #f3fcff);
}

.rulebox3 {
  height: 380px;
  background-image: linear-gradient(#ffffe7, #ffffeb);
  clip-path: polygon(0 0, 100% 0, 100% calc(100% - 5vw), 0 100%);
  /* change the calc height to a percentage height to get alternate responsive behavior*/
}

.rulebox4 {
  margin-top: -5vw;
  background-image: linear-gradient(135deg, #029afb, #385a80);
}

.fiveflights {
  background-image: linear-gradient(#ff9c80, #ffb9a6);
  color: #fff;
}

.fiveflights p {
  color: #fff;
}

.lastminute {
  background-image: linear-gradient(135deg, #f0fcff, #f3fcff);
}

.radius {
  background-image: linear-gradient(#ffffe7, #ffffeb);
}

.pricing {
  background-image: linear-gradient(135deg, #029afb, #385a80);
  color: #fff;
}

.pricing p {
  color: #fff;
}
.title {
  font-size: 32px;
}

.fiveflights {
  background: #ff9c80;
  margin: 0 auto;
  text-align: center;
  font-weight: bold;
  color: white;
  background-image: linear-gradient(
    to left,
    transparent,
    transparent 50%,
    #00c6ff 50%,
    #00c6ff
  );
  background-position: 100% 0;
  background-size: 200% 100%;
  transition: all 0.25s ease-in;
}
.fiveflights:hover {
  background-position: 0 0;
  color: #333;
}

.rules-box-body {
  border: 6px solid #385a80;
  padding: 40px 20px;
  margin-top: -20px;
}

.rules-box-header {
  font-size: 32px;
  font-family: "Graphik Bold", "Segoe UI", Roboto, "Helvetica Neue", Arial,
    sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  background-color: #fff;
  display: inline;
  text-align: center;
  padding: 0px 20px;
}

.rules-box-icon {
  margin-top: -30px;
}

.rules-box-icon.plane img {
  background: #fff;
  display: inline;
  padding: 0px 10px;
}

.rules-box-icon.geo img {
  background: #fff;
  display: inline;
  padding: 0px 10px;
}
.rules-box-icon.clock img {
  background: #fff;
  display: inline;
  padding: 0px 10px;
}
.rules-box-icon.money img {
  background: #fff;
  display: inline;
  padding: 0px 10px;
}

.rule-boxes {
  margin-bottom: 40px;
}

.ruleh2 {
  margin-bottom: 20px !important;
}

.ruleh2 .salmon {
  color: #ff9c80;
}

.move-up-95 {
  margin-top: -95px;
}

/* .rules-box-section {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}
.rules-box {
  flex-basis: calc(50% - 20px);
  border: 6px solid #385a80;
  margin: 10px;
  padding: 20px;
}


@media (max-width: 768px) {
  .rules-box {
    flex-basis: calc(90% - 20px);
  }
} */
.video-section {
  background: #ffffe7;
  padding: 120px 0px;
}

.video-stuff {
  display: flex;
  align-items: center;
  justify-content: center;
}

.video-content,
.video-content-last {
  font-weight: bold;
  font-size: 28px;
  /* padding: 10px 0px; */
  line-height: 1.4;
}

.video-content-last:after {
  background-color: #385a80;
  bottom: -20px;
  content: "";
  display: block;
  height: 10px;
  position: relative;
  width: 100px;
}

.join-section-destinations {
  background-size: cover;
  position: relative;
}

.join-header {
  font-weight: bold;
  font-size: 28px;
}

.join-body {
  font-size: 24px;
}

.join-stuff {
  min-height: 45vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.join-section-bottom {
  background: url("https://s3-us-west-1.amazonaws.com/assets.flyskyhi.com/images/vertical-bars_orange%402x.png")
    repeat-x;
  background-size: contain;
  height: 100px;
  position: absolute;
  width: 100%;
  opacity: 0.8;
  bottom: -2%;
}

.footer-top2 {
  background: rgb(53, 174, 252);
  padding: 40px 0px;
  color: #fff;
}
.footer-header2 {
  color: #fff;
  font-weight: bold;
  margin-bottom: 10px;
}
.footer-links a {
  color: #fff;
  font-weight: bold;
  border-bottom: 2px solid #fff;
}
/* .footer-links a:after {
  background-color: #385a80;
  content: "";
  bottom: -5px;
  display: block;
  height: 5px;
  position: relative;
  width: 100px;
} */
.footer-links li {
  margin-bottom: 10px;
}

.footer-flex {
  display: flex;
  justify-content: center;
}
.footer-column {
  align-content: center;
  flex: 1;
}

.footer-column.contact {
  font-weight: bold;
}
.social-flex-items {
  margin-top: 10px;
  width: 70%;
  display: flex;
  justify-content: space-between;
}
.social-item {
  flex: 1;
}

.social-item .fa {
  color: #fff;
}

.footer-column.subscribe2 {
  flex: 2;
  padding: 0px 20px;
}

.flex-icons-download {
  flex-direction: column;
}
.icon-1 {
  margin: 5px 0px;
  width: 75%;
}

@media (max-width: 1200px) {
  .footer-column.subscribe2 {
    flex: 3;
  }
  .flex-icons-download {
    flex-direction: column;
  }
  .icon-1 {
    margin: 5px 0px;
  }
}

.flex-icons-download {
  display: flex;
}
.icon-1 {
  flex: 1;
  padding-right: 10px;
  width: 100%;
}
.footer-middle {
  border: 4px dashed #baefff;
}

.press-partners {
  padding: 80px 0px;
}

.press-flex {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.press-item {
  flex: 1;
  padding: 20px;
  min-width: 16.6666666667%;
}

@media (max-width: 992px) {
  .city-image-flex {
    display: none;
  }
  .press-item {
    min-width: 50%;
  }
  .airlines-home p {
    text-align: center;
  }
}

#press2 {
  margin-bottom: 80px;
}

.h-section-flex {
  flex-direction: column;
  justify-content: space-around;
}
.h-section {
  display: flex;
  align-items: stretch;
  margin: 40px 0px;
  flex: 1;
}
.h-image {
  flex: 1;
  background-color: yellow;
  align-items: stretch;
  display: flex;
  justify-content: center;
  align-items: center;
}
.h-image-content {
  padding: 60px;
}
.h-image-content h2 {
  font-family: "Bebas Neue Regular";
}

.h-5 {
  background: url("https://s3-us-west-1.amazonaws.com/assets.flyskyhi.com/images/get-access_bg%402x.png")
    no-repeat;
  background-size: cover;
  text-align: center;
}
.h-seat {
  background: url("https://s3-us-west-1.amazonaws.com/assets.flyskyhi.com/images/find-seat_bg%402x.png")
    no-repeat;
  background-size: cover;
  text-align: center;
}
.h-fly {
  background: url("https://s3-us-west-1.amazonaws.com/assets.flyskyhi.com/images/fly-skyhi_bg%402x.png")
    no-repeat;
  background-size: cover;
  text-align: center;
}
.h-content {
  flex: 2;
  display: flex;
  flex-direction: column;
}
.h-content-div {
  background: #ffffe7;
  flex: 2;
  display: flex;
  /* justify-content: center; */
  align-items: center;
}
.h-top {
  font-size: 28px;
  font-weight: bold;
  text-transform: uppercase;
}
.h-content-div:first-child {
  border-bottom: dashed 5px #f8d948;
}
.h-image.head {
  min-width: 364px;
  min-height: 295px;
}
.h-content-div-image {
  padding: 20px;
}
.h-content-div-image.top {
  width: 11%;
}

.h-content-div p {
  padding: 20px 10px;
}
.h-content-div.mid {
  padding: 0px 20px;
}
.h-content-div.last {
  background: #ff9c80;
  flex: 1;
  border-bottom: none;
  justify-content: center;
  color: #fff;
}
.h-content-div.last a {
  color: #fff;
  text-decoration: underline;
  font-size: 28px;
  font-weight: bold;
}
.h-bottom-section {
  display: flex;
  align-items: stretch;
}
.h-bottom-price {
  flex: 1;
  background: rgb(254, 244, 191);
  display: flex;
  justify-content: center;
  align-items: center;
}
.h-bottom-content {
  flex: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background: rgb(241, 252, 254);
}

.why-section {
  padding: 40px 0px;
}

.why-section h3 {
  font-size: 26px;
  font-weight: bold;
}

.lessmore,
.travelexplore {
  display: flex;
}
.lessmore-left {
  flex: 1;
  background: rgb(254, 244, 191);
  display: flex;
  justify-content: center;
}
.lessmore-right {
  flex: 1;
  background: #ff9c80;
  display: flex;
  justify-content: center;
}
.travelexplore-left {
  flex: 1;
  background: rgb(241, 252, 254);
  display: flex;
  justify-content: center;
}
.travelexplore-right {
  flex: 1;
  background: rgb(255, 255, 231);
  display: flex;
  justify-content: center;
}
.lessmore-content,
.travelexplore-content {
  padding: 60px 0px;
}

.lessmore-header h3 {
  font-size: 26px;
  margin-bottom: -20px;
}
.travelexplore-header h3 {
  font-size: 26px;
  margin-bottom: -20px;
}

.why-box-body {
  border: 6px solid #385a80;
  padding: 40px 40px;
  margin-top: -40px;
  width: 75%;
  max-width: 548px;
}
.less-box-header {
  background: rgb(254, 244, 191);
  padding: 0px 20px;
  display: inline;
  z-index: 1;
}
.less-box-header h3 {
  font-family: "Graphik Bold", "Segoe UI", Roboto, "Helvetica Neue", Arial,
    sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 26px;
  display: block;
}
.travel-box-header {
  background: rgb(241, 252, 254);
  padding: 0px 20px;
  display: inline;
  z-index: 1;
}
.travel-box-header h3 {
  font-family: "Graphik Bold", "Segoe UI", Roboto, "Helvetica Neue", Arial,
    sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 26px;
  display: block;
}

.compass-box-header {
  background: rgb(255, 255, 231);
  padding: 0px 20px;
  display: inline;
  z-index: 1;
}
.compass-box-header h3 {
  font-family: "Graphik Bold", "Segoe UI", Roboto, "Helvetica Neue", Arial,
    sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 26px;
  display: block;
}

.lessmore-header h3 {
  background: rgb(254, 244, 191);
}

.darker-yellow {
  background: rgb(254, 244, 191);
}

.darker-yellow {
  background: rgb(254, 244, 191);
}
.light-blue {
  background: rgb(241, 252, 254);
}
.why-box-icon {
  margin-top: -30px;
}

.why-box-icon.plane img {
  background: rgb(254, 244, 191);
  display: inline;
  padding: 0px 10px;
}

.why-box-icon.compass img {
  background: rgb(241, 252, 254);
  display: inline;
  padding: 0px 10px;
}

.why-box-icon.geo img {
  background: rgb(255, 255, 231);
  display: inline;
  padding: 0px 10px;
}

.why-left,
.why-right {
  padding: 80px;
  width: 65%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.why-right {
  padding: 0px;
  width: 35%;
}

@media (max-width: 992px) {
  .why-left {
    width: 100%;
  }
}

.salmonish {
  background: rgb(255, 213, 181);
}

.light-yellow {
  background: rgb(255, 255, 231);
}

.tablet-footer-download {
  display: none;
}
.h-bottom-section-mobile {
  display: none;
}

.intro-text-mobile {
  display: none;
}

.rules-flex-row {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.rules-flex-box {
  flex: 1;
  border: 4px solid #385a80;
  padding: 40px 30px;
  min-height: 275px;
}
.rules-flex-box .rules-box-header {
  padding-left: 0px;
}

.rules-box-header:after {
  background-color: #385a80;
  bottom: -10px;
  content: "";
  display: block;
  height: 10px;
  position: relative;
  width: 10%;
  text-align: center;
  left: 0%;
}

.rules-flex-img {
  flex: 1;
  display: flex;
  justify-content: center;
}

.rules-1500-miles .rules-flex-box {
  order: 2;
}

.rules-1-price .rules-flex-box {
  order: 2;
}

/* .rules-5-flights .rules-flex-img img {
  width: 70%;
}

.rules-1500-miles .rules-flex-img img {
  width: 70%;
}

.rules-10-days .rules-flex-img img {
  width: 70%;
}

.rules-1-price .rules-flex-img img {
  width: 70%;
} */

.rules-flex-img img {
  margin: auto;
}

.airlines-home {
  background: #385a80;
  text-align: center;
  padding: 80px 0px;
}

.airlines-home h2 {
  color: #fff;
}

.airlines-home p {
  color: #fff;
  padding-bottom: 40px;
}

.flex-airlines-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.cities-form {
  border: 3px solid #385a80;
  padding: 40px;
  margin: 0px 40px;
}
.city-vote {
  padding: 0px 20px;
  margin: 0px 40px;
}
.city-vote-input {
  height: 40px;
  border: none;
  padding: 20px;
}
.btn-city {
  height: 40px;
  padding: 0px 20px;
  background: #385a80;
  color: #fff;
}

/* --------------------------------------------------- */
@media (min-width: 768px) {
  .footer2mobile {
    display: none;
  }
}

@media (min-width: 992px) {
  .intro-fly {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .intro-header {
    flex: 1;
    padding-right: 80px;
  }
  .hero-phone {
    display: flex;
  }

  .hero-phone-mobile {
    display: none;
  }
  .mobile-intro {
    display: none;
  }
}

@media (max-width: 992px) {
  .cities-form {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .city-form-header {
    display: block;
    width: 100%;
  }
  .city-vote {
    display: flex;
  }
  .desktop-intro {
    display: none;
  }
  .intro-block {
    background: rgb(241, 252, 254);
    padding: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .hero-phone {
    display: none;
  }
  .hero-phone-mobile {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 40px;
    background: rgb(255, 255, 233);
  }
  .hero-phone-mobile img {
    width: 60%;
  }
  .arrowdown {
    display: none;
  }
  p {
    line-height: inherit;
  }
  .navbar-nav {
    justify-content: center;
    align-items: center;
    padding-bottom: 20px;
  }
  .navbar-app {
    width: 50%;
  }
  .navbar-brand {
    margin: 0 auto;
    padding-left: 56px;
  }
  .navbar-light .navbar-toggler {
    border: none;
  }
  .join-section-content p {
    text-align: center;
  }
  .footer-flex {
    flex-wrap: wrap;
  }
  .footer-column.subscribe2 {
    padding: 0px 60px;
  }
  .footer-column {
    padding: 0px 60px;
  }
  .tablet-footer-download {
    display: block;
  }
  .hero-phone img {
    padding: 40px 0px;
  }
  .destinations-hero-content p {
    text-align: center;
  }
  .subheader-content {
    width: 100% !important;
    text-align: center;
  }
  .h-content-div.last a {
    font-size: 20px;
  }
  .h-content-div.mid {
    padding: 20px;
  }
  .why-right {
    display: none;
  }
  .destination-hero {
    padding: 3rem;
  }
  h2 {
    font-size: 40px;
  }
  .rules-intro-text {
    padding: 10px 20px;
  }
  .h-section {
    flex-direction: column;
    align-items: stretch;
    width: 100%;
    flex: 1;
  }
  .h-image {
    margin-bottom: 40px;
  }
  .h-bottom-section {
    display: none;
  }
  .h-bottom-section-mobile {
    display: block;
  }
  .join-section-how {
    border-top: 4px dashed #baefff;
  }
  .how-pricing {
    padding: 40px 0px;
    min-height: 35vh;
    display: flex;
    align-items: center;
  }
  .intro-text-desktop {
    display: none;
  }
  .intro-text-mobile {
    display: block;
    margin-bottom: 20px;
  }
  .embed-responsive {
    margin-top: 40px;
  }
  .rules-flex-row {
    margin: 20px 0px;
  }
}
@media (max-height: 1024px) {
  .mobile-intro {
    min-height: 90vh;
  }
}

@media (max-width: 992px) and (min-width: 768px) {
  .tablet-footer-download {
    margin-top: 15px;
  }
  .tablet .icon-1 {
    width: 75%;
  }

  .footer-column.app {
    display: none;
  }
  .hero-phone img {
    width: 50%;
  }
  .intro-section .intro-fly h2 {
    padding-bottom: 20px;
  }
  .rules-flex-img img {
    width: 50%;
  }
  .flex-airline-logo {
    margin: 10px 40px;
  }
}

@media (max-width: 767px) {
  .only-mobile {
    display: block;
  }
  .intro-section h1 {
    font-size: 30px;
    line-height: initial;
  }
  .intro-section h2 {
    font-size: 30px;
    padding-bottom: 20px;
  }
  .hero-phone img {
    width: 75%;
  }
  .footer2desktop {
    display: none;
  }
  .mobile-social-flex-items {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .mobile-social-item a {
    font-size: 2em;
    color: #fff;
  }
  .footer-flex {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .footer-column {
    padding: 0px 45px;
  }
  .footer-header2 {
    color: #385a80;
  }
  .footer-column.app p {
    font-size: 24px;
  }
  .flex-icons-download {
    flex-direction: row;
  }
  .mobile-footer-section {
    padding: 40px 0px;
    border-bottom: 4px dashed #baefff;
  }
  .footer-column.contact {
    font-size: 18px;
    font-weight: bold;
    color: #385a80;
    font-family: "Graphik Bold", "Segoe UI", Roboto, "Helvetica Neue", Arial,
      sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  }
  .btn-footer-bottom {
    color: #385a80;
    border: 3px solid #385a80;
  }
  .h-section {
    margin: 40px 0px;
  }
  .h-content-div-image.top {
    width: 40%;
  }
  .rules-flex-box {
    flex: auto;
  }
  .rules-flex-img img {
    display: none;
  }
  .flex-airline-logo {
    margin: 10px 40px;
  }
}

@media (max-width: 1200px) {
  .why-box-body {
    width: 100%;
  }
}
@media (max-width: 576px) {
  .intro-section .intro-fly h2 {
    padding: 20px 0px;
  }
  footer .input--hideo {
    width: 100%;
  }
  .btn-footer-bottom {
    width: 100%;
    margin-left: 0px;
    margin-top: 10px;
  }
}
@media (max-width: 420px) {
  .why-left {
    padding: 40px 20px;
  }
}

@media (max-width: 991px) and (min-width: 768px) {
  .tablet-subscribe {
    margin-top: 20px;
  }
  .only-mobile {
    display: block;
  }
}

@media (max-width: 992px) and (min-width: 991px) {
  .footer-column {
    padding: 0px 40px;
  }
  .footer-column.subscribe2 {
    padding: 0px 0px;
  }
  .tablet-subscribe {
    margin-top: 0px;
  }
}

@media (width: 991px) {
  .btn-footer-bottom {
    width: 100%;
    margin-top: 5px;
    margin-left: 0px;
  }
}

@media (max-width: 320px) {
  .flex-icons-download {
    flex-direction: column;
  }
  .intro-section .intro-fly h2 {
    padding-top: 10px;
  }
  .hero-phone img {
    padding-bottom: 20px;
  }
  .intro-section .intro-fly .btn {
    margin-top: 5px;
  }
  .join-stuff {
    padding: 40px 0px;
  }
}

@media (max-width: 992px) {
  .video-section {
    padding: 60px 0px;
  }
}

@media (max-width: 434px) {
  .origin .dropdown {
    display: block;
  }
}
.dropdown-inline {
  font-size: 28px;
}
.statsflex {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
}

.stats-box {
  background: #385a80;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px 40px;
  width: 32%;
}
.stats-box p {
  color: #fff;
}
.stats-box p.number {
  font-family: "Graphik Bold", "Segoe UI", Roboto, "Helvetica Neue", Arial,
    sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 26px;
}
.dropdown-menu {
  transform: translate3d(5px, 35px, 0px) !important;
}
.city-image-flex {
  width: 100%;
}

.city-image-flex img {
  max-height: 225px;
}
/* 
#center-plane-border {
  stroke: #385a80;
  stroke-width: 2px;
}

@keyframes offset {
  to {
    stroke-dashoffset: 0px;
  }
  to {
    stroke-dashoffset: 74px;
  }
}

.rules-plane-svg {
  animation: offset 8s linear forwards;
}

.rules-plane-svg {
  stroke-dasharray: 2020px;
  stroke-dashoffset: 2020px;
} */

.more-cities {
  background: rgb(255, 213, 181);
  padding: 40px 0px;
}

.cities-form {
  display: flex;
  justify-content: center;
  align-items: center;
}
.cities-form.column {
  flex-direction: column;
}

.btn-login {
  margin: 10px 0px;
}
.login-link {
  font-family: "Graphik Bold", "Segoe UI", Roboto, "Helvetica Neue", Arial,
    sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  color: #ff9c80;
  font-size: 18px;
  padding: 0.5rem;
  margin-top: 5px;
}

.login-border {
  border: 5px solid #385a80;
  padding: 40px;
  text-align: center;
}

.login-border h2 {
  font-size: 30px;
}

.login-arrow-container {
  width: 50%;
  height: 5vh;
}

@media (max-width: 992px) {
  .login-arrow-container {
    width: 100%;
  }
}

@media (max-width: 992px) {
  .login-border {
    width: 70%;
  }
  .login-border p {
    text-align: center;
  }
  .login-border .btn-login {
    font-size: 1.2rem;
  }
}

.earlyaccess-page {
  display: flex;
  justify-content: center;
  align-items: center;
}
.pricingtable {
  background: #fff;
}
/* .pricing-img {
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
} */

.plane-left {
  margin-left: -15px;
}
.pricing-content {
  padding: 40px;
}

h2.sal.mobile-sb {
  font-family: "Graphik Bold", "Segoe UI", Roboto, "Helvetica Neue", Arial,
    sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 28px;
  text-align: center;
}

.priceblock {
  display: block;
}
.currency {
  font-size: 2.75rem;
  font-family: "Graphik Bold", "Segoe UI", Roboto, "Helvetica Neue", Arial,
    sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  color: #ff9c80;
}
.period {
  font-size: 1rem;
  font-family: "Graphik Bold", "Segoe UI", Roboto, "Helvetica Neue", Arial,
    sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  color: #ff9c80;
}

.pricing-meta {
  text-align: center;
}

.discount-pad {
  padding: 5px 0px;
}

.originalprice {
  text-align: center;
  padding: 10px 0px;
}

.form-check-inline {
  margin-right: 0rem;
}

.travelinfo h3 {
  line-height: initial;
}

.list-of-cities li {
  font-size: 20px;
}

.ohplaces h2 {
  font-size: 28px;
}
.price-table-large {
  background: rgb(255, 255, 231);
  padding: 40px;
}

.table-flex-column {
  display: flex;
  flex-direction: column;
}

.table-head {
  text-align: center;
  border: 4px solid #385a80;
  border-bottom: none;
  padding: 20px;
}

.table-head h2 {
  font-size: 40px;
}

.table-row {
  display: flex;
  flex-direction: row;
}
.table-row {
  border: 4px solid #385a80;
}

.table-row-single {
  display: flex;
  flex-direction: row;
  border-bottom: 4px solid #385a80;
  border-left: 4px solid #385a80;
  text-align: center;
}

.alpha-head {
  border-bottom: 4px solid #385a80;
}

.table-row:last-child {
  border-top: none;
}

.table-cell {
  flex: 1;
  text-align: center;
  padding: 20px;
}

.table-cell-single {
  flex: 1;
  text-align: center;
  padding: 20px;
  border-right: 4px solid #385a80;
}

.table-cell:first-child {
  border-right: 4px solid #385a80;
}

.table-content {
  font-size: 24px;
  font-family: "Graphik Bold", "Segoe UI", Roboto, "Helvetica Neue", Arial,
    sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  text-align: left;
}

.table-content-smaller {
  font-size: 20px;
  text-align: left;
}

.appscreens {
  padding: 80px 0px;
}

.screen {
  border: 4px solid #385a80;
  padding: 40px;
  padding-bottom: 0px;
}

.screen-center {
  margin: 0 auto;
}
.screen-icon {
  background: #fff;
  width: 25%;
  margin: 0 auto;
  margin-top: -80px;
}

@media (max-width: 1200px) {
  .screen-icon {
    margin-top: -70px;
  }
}

@media (max-width: 992px) {
  .screen {
    margin-bottom: 60px;
  }
  .table-row {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    border-bottom: none !important;
  }
  .table-cell {
    display: block;
    border-right: none !important;
    border-bottom: 4px solid #385a80;
    width: 100%;
  }
  .table-content {
    font-size: 22px;
  }
  .table-cell:nth-child(2) {
    border-bottom: none;
  }
  .last-table-cell {
    border-bottom: 4px solid #385a80 !important;
  }
}

.screen-content {
  padding: 40px 0px;
}

.screen-content p {
  font-family: "Graphik bold", "Segoe UI", Roboto, "Helvetica Neue", Arial,
    sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 24px;
}

/* @media (max-width: 992px) {
  .screen {
    margin-bottom: 50px;
  }
} */

.applink {
  text-align: center;
  margin-bottom: 5px;
}

.tofrom {
  font-family: "Graphik bold", "Segoe UI", Roboto, "Helvetica Neue", Arial,
    sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}
.destinations-list-invite li {
  font-size: 22px;
}

.facebook-login-flex {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 75vh;
}

button.social-login-button {
  display: block;
  border: 0px;
  border-radius: 3px;
  box-shadow: rgba(0, 0, 0, 0.5) 0px 1px 2px;
  color: rgb(255, 255, 255);
  cursor: pointer;
  font-size: 19px;
  margin: 5px;
  width: 230.66px;
  overflow: hidden;
  padding: 0px 10px;
  user-select: none;
  height: 50px;
}

button.social-login-button > div {
  align-items: center;
  display: flex;
  height: 100%;
}

.social-login-svg-container {
  display: flex;
  justify-content: center;
  min-width: 26px;
}

button.social-login-button.google {
  background: rgb(203, 63, 34);
}

button.social-login-button.facebook {
  background: rgb(59, 89, 152);
}

button.social-login-button.google:hover {
  background: rgb(165, 51, 28);
}

button.social-login-button.facebook:hover {
  background: rgb(41, 62, 105);
}

button.social-login-button.google.inactive:hover {
  cursor: progress;
  background: rgb(203, 63, 34);
}

button.social-login-button.facebook.inactive:hover {
  cursor: progress;
  background: rgb(59, 89, 152);
}

.social-login-button-padding {
  width: 10px;
}

.social-login-button-text {
  text-align: left;
  width: 100%;
}

.fb-page {
  background: #fff4bf;
  background: -webkit-linear-gradient(top, #fff4bf, #ffffff);
  background: -moz-linear-gradient(top, #fff4bf, #ffffff);
  background: -ms-linear-gradient(top, #fff4bf, #ffffff);
  background: -o-linear-gradient(top, #fff4bf, #ffffff);
  background: linear-gradient(to bottom, #fff4bf, #ffffff);
}

input {
  outline: none;
  border: none;
}

textarea {
  outline: none;
  border: none;
}

select {
  outline: none;
  border: none;
}

textarea:focus,
input:focus,
select:focus {
  border-color: transparent !important;
}

input::placeholder {
  color: #999999;
}

input::-webkit-input-placeholder {
  color: #999999;
}
input::-moz-placeholder {
  color: #999999;
  opacity: 1;
}
input::-ms-input-placeholder {
  color: #999999;
}

textarea::-webkit-input-placeholder {
  color: #999999;
}
textarea::-moz-placeholder {
  color: #999999;
  opacity: 1;
}
textarea::-ms-input-placeholder {
  color: #999999;
}

select::-webkit-input-placeholder {
  color: #999999;
}
select::-moz-placeholder {
  color: #999999;
  opacity: 1;
}
select::-ms-input-placeholder {
  color: #999999;
}

.inputwrap {
  /* width: 50%; */
  margin: 0 auto;
  padding: 0px 10px;
}
/*------------------------------------------------------------------
[ Input ]*/

.wrap-input100 {
  width: 100%;
  position: relative;
  background-color: #fff;
  margin-bottom: 30px;
}

select.input100 {
  cursor: pointer;
}

.input100 {
  font-family: "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol";
  font-weight: 500;
  display: block;
  width: 100%;
  background: transparent;
  font-size: 16px;
  color: #4b2354;
  line-height: 1.2;
}

.input-flex-wrapper {
  display: flex;
}

.input-flex-wrapper .wrap-input100 {
  flex: 1;
  width: 100%;
  margin-right: 4%;
}
.input-flex-wrapper .wrap-input100.last {
  margin-right: 0%;
}

.wrap-input100promo {
  width: 100%;
  position: relative;
  background-color: #fff;
}
.wrap-input100-promo-button {
  height: 100%;
}

/*---------------------------------------------*/
input.input100 {
  height: 62px;
  padding: 0 20px 0 23px;
}

div.input100.StripeElement {
  height: 62px;
  padding: 22px 10px 0 10px;
  cursor: text;
}

textarea.input100 {
  min-height: 199px;
  padding: 19px 20px 0 23px;
}

select.input100 {
  height: 62px;
  padding: 0 20px 0 23px;
}

select {
  display: inline-block;
  font: inherit;
  margin: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;
  background: url("https://s3-us-west-1.amazonaws.com/assets.flyskyhi.com/images/kisspng-arrow-symbol-icon-down-arrow-png-pic-5a756e2591b7b9.1532686215176453495969.png")
    no-repeat !important;
  background-position-x: 98% !important;
  background-position-y: 50% !important;
}
/*------------------------------------------------------------------
[ Focus Input ]*/

.focus-input100 {
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none;
  box-shadow: 0 5px 20px 0px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0 5px 20px 0px rgba(0, 0, 0, 0.05);
  -webkit-box-shadow: 0 5px 20px 0px rgba(0, 0, 0, 0.05);
  -o-box-shadow: 0 5px 20px 0px rgba(0, 0, 0, 0.05);
  -ms-box-shadow: 0 5px 20px 0px rgba(0, 0, 0, 0.05);

  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
  transition: all 0.4s;
}

.input100:focus + .focus-input100,
.focus-input100.focused {
  box-shadow: 0 5px 20px 0px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0 5px 20px 0px rgba(0, 0, 0, 0.15);
  -webkit-box-shadow: 0 5px 20px 0px rgba(0, 0, 0, 0.15);
  -o-box-shadow: 0 5px 20px 0px rgba(0, 0, 0, 0.15);
  -ms-box-shadow: 0 5px 20px 0px rgba(0, 0, 0, 0.15);
}

.account-page {
  background: #ffffe9;
}

.account-page label {
  font-weight: bold;
}

.compliance-switch {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 0px;
}

.account-page h2,
h3,
h4 {
  text-align: center;
}

.wrap-input100-select {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.wrap-input100-select input[type="checkbox"] {
  float: right;
}

.wrap-input100-select label {
  font-weight: bold;
  font-size: 14px;
  flex: 1;
}

.wrap-input100-button {
  padding: 30px 0px;
}

.form-group1 {
  border-bottom: dashed 5px #f8d948;
  padding: 50px 0px;
}

.form-group {
  border-bottom: dashed 5px #f8d948;
  padding: 50px 0px;
}

.progress-btn {
  background-color: transparent !important;
}

.progress-btn > button {
  height: 62px !important;
  background-color: transparent !important;
}

.progress-btn > button:hover {
  background-color: #ff9c80 !important;
}

.progress-btn > button > div {
  border: 6px solid #385a80;
  height: 62px !important;
}

.progress-btn > button > div:hover {
  border: 6px solid #ff9c80 !important;
  background-color: #ff9c80 !important;
}

.progress-btn > button > div > span {
  color: #385a80 !important;
  font-family: "Graphik Bold", "Segoe UI", Roboto, "Helvetica Neue", Arial,
    sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !important;
  font-size: 30px !important;
  top: 10px !important;
}

.progress-btn > button > div > span:hover {
  color: #fff !important;
}

.progress-btn-back {
  background-color: transparent !important;
  color: #ff9c80 !important;
  font-family: "Graphik Bold", "Segoe UI", Roboto, "Helvetica Neue", Arial,
    sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !important;
}

.progress-btn-back > span {
  font-size: 28px !important;
}
/* .styles_modal__gNwvD {
  background: #ffffe9;
}


.styles_overlay__CLSq- {
  padding: 0px;
}

@media(min-width: 992px) {
  .styles_overlay__CLSq- {
    align-items: center;
  }
}
.step-block {
  min-width: 350.63px;
} */

/* Media Queries */

@media (max-width: 992px) {
  .styles_modal__gNwvD {
    min-height: 100vh;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    height: 100%;
    margin-bottom: 10vh;
  }
}

/* Reusable Components */

.ctabar {
  background-color: #ff9c80;
  color: #fff;
  font-size: 28px;
  font-family: "Graphik Bold", "Segoe UI", Roboto, "Helvetica Neue", Arial,
    sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  padding: 10px 0px;
}
.ctabar a {
  color: #fff;
  text-decoration: underline;
}

.join-section {
  position: relative;
}

.join-yellow {
  background: url("./images/yellow-plane_bg@2x.png") no-repeat;
  background-size: cover;
}

.join-blue {
  background: url("./images/Blue-Plane_bg@2x.png") no-repeat;
  background-size: cover;
}

.top-hero {
  background: url("./images/sky-clouds_bg@2x.png") no-repeat;
  background-size: cover;
  padding: 5rem;
  text-align: center;
}

.top-hero-content h3 {
  font-size: 28px;
  font-family: "Graphik Medium", "Segoe UI", Roboto, "Helvetica Neue", Arial,
    sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  color: rgb(55, 90, 128);
  letter-spacing: inherit;
}

.top-hero-content h3:before {
  content: "";
  position: absolute;
  width: 50%;
  height: 1px;
  bottom: 0;
  left: 25%;
  border-bottom: 1px solid red;
}

.subheader-title {
  font-weight: bold;
  font-size: 28px;
  /* padding: 10px 0px; */
  line-height: 1.4;
}

.subheader-title:after {
  background-color: #385a80;
  bottom: -10px;
  content: "";
  display: block;
  height: 10px;
  position: relative;
  width: 20%;
  text-align: center;
  left: 40%;
}

.subheader-div2 {
  display: flex;
  justify-content: center;
}
.subheader-content {
  width: 25%;
  margin: 0 auto;
}

.form-container {
  max-width: 375px;
  margin: 0 auto;

  h2 {
    text-align: center;
  }
}

.form-group-top {
  min-height: 50vh;
}

.wrap-input100.selector {
  width: 95%;
  margin-left: 2%;
}

.btn-blue.smaller {
  font-size: 24px;
  padding-top: 6px;
  margin-bottom: 10px;
}

.payment-flow-buttons {
  display: flex;
  justify-content: space-around;
}

.account-page h2 {
  font-size: 43px;
}

/* Stepper */
.MuiPaper-root-8 {
  background-color: transparent !important;
}

@media (max-width: 992px) {
  .MuiStepper-root-4 {
    padding-bottom: 10px !important;
  }
}

.pending-payment {
  padding: 80px 0px;
}

.account-container {
  padding: 80px 0px;
}

.section-form {
  padding: 80px 0px;
}

.input100.is-invalid {
  border: 1px solid #dc3545;
}

.error-text {
  margin-top: -20px;
  margin-bottom: 10px;
  font-size: 14px;
  color: red;
}

.styles_overlayCenter__YHoO7 {
  align-items: flex-start !important;
}
.popup-content {
  max-height: 100vh;
  -webkit-overflow-scrolling: touch;
  overflow: auto;
  width: 100% !important;
}

body.popup-overlay {
  overflow: hidden !important;
}

/*progressbar*/
#progressbar {
  margin-bottom: 30px;
  overflow: hidden;
  /*CSS counters to number the steps*/
  counter-reset: step;
}

#progressbar li {
  list-style-type: none;
  color: blue;
  text-transform: uppercase;
  font-size: 9px;
  width: 33.33%;
  float: left;
  position: relative;
  letter-spacing: 1px;
}

#progressbar li:before {
  content: counter(step);
  counter-increment: step;
  width: 24px;
  height: 24px;
  line-height: 26px;
  display: block;
  font-size: 12px;
  color: #333;
  background: blue;
  border-radius: 25px;
  margin: 0 auto 10px auto;
}

/*progressbar connectors*/
#progressbar li:after {
  content: "";
  width: 100%;
  height: 2px;
  background: blue;
  position: absolute;
  left: -50%;
  top: 9px;
  z-index: -1; /*put it behind the numbers*/
}

#progressbar li:first-child:after {
  /*connector not needed before the first step*/
  content: none;
}

/*marking active/completed steps green*/
/*The number of the step and the connector before it = green*/
#progressbar li.active:before,
#progressbar li.active:after {
  background: #ee0979;
  color: blue;
}

.formgroup {
  padding: 20px 0px;
}

.inputwrap {
  .input-collapse {
    max-height: auto;
    -moz-transition: 1s;
    -ms-transition: 1s;
    -o-transition: 1s;
    -webkit-transition: 1s;
    transition: 1s;
  }
  &.is-collapsed {
    .input-collapse {
      max-height: 0em;
      overflow: hidden;
      -moz-transition: 1s;
      -ms-transition: 1s;
      -o-transition: 1s;
      -webkit-transition: 1s;
      transition: 1s;
    }
  }
}
.btn {
  .btn-submit-payment {
    width: 100%;
  }
}

.edit-card-link {
  padding-top: 10px;
  margin-left: 12px;
  font-size: 15px;
  color: #385a80;
  font-weight: bold;
  cursor: pointer;
}

.is-shown {
  display: none;
}

.input100.country-select {
  select {
    height: 62px;
    padding: 0 20px 0 23px;
    width: 100%;
    cursor: pointer;

    option: {
      color: black;
    }
  }
}

$rccs-card-ratio: 0.7929 !default;
$rccs-size: 145px !default;

.rccs {
  &__card {
    height: $rccs-size / $rccs-card-ratio !important;
  }
}

.modal-body {
  min-height: 100vh !important;
  max-height: 100vh !important;
  margin: 0 !important;
  padding: 0 !important;
}

.MuiPaper-root-52,
.MuiPaper-elevation24-78,
.MuiPaper-rounded-53,
.MuiDialog-paper-40,
.MuiDialog-paperScrollBody-42,
.MuiDialog-paperWidthSm-44 {
  max-width: none !important;
  width: 100% !important;
  margin: 0px !important;
  height: 100vh !important;
}

.MuiDialogActions-root-79,
.modal-full {
  background: #ffffe9 !important;
  margin: 0px !important;
}

/* Invited Page */
.product-page-header {
  background-color: #3a5a7e;
  h2,
  p,
  span {
    color: #fff;
    text-align: center;
  }
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 60px 0px;
  min-height: 40vh;
}

.product-pricing {
  display: flex;
  justify-content: center;
  align-items: center;
  .pricing-card {
    margin-top: -100px;
    @media (max-width: 992px) {
      margin-top: -80px;
    }
    background-color: #f0fcff;
    padding: 40px 80px;
    border-radius: 8px;
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2),
      0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12);

    ul {
      li.pricing-title {
        font-size: 22px;
        font-weight: bold;
        text-align: center;
      }
    }
  }
}

/* Loading component for payment flow */
.loader-flex {
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.loader-inner-text {
  padding: 5vh;
}

/* Landing Page for Payment */

.flyabove {
  background-color: rgb(241, 252, 255);
}
.flyabove-flex {
  padding-top: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.flyabove-flex img {
  margin-top: 40px;
  width: 35vh;
}

.descriptor {
  padding: 40px 0px;
}

.descriptor-flex {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

@media (max-width: 992px) {
  .descriptor-flex {
    flex-direction: column;
  }
  .boarding-pass {
    order: 0;
  }
  .describe {
    order: 2;
    padding-top: 20px;
  }
}

.describe,
.boarding {
  flex: 1;
  margin: 0 10px;
}

.smaller {
  font-size: 16px;
}

/* Boarding Pass */

.boarding-pass-page {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.boarding-pass {
  border-radius: 14px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  font-family: "Graphik Bold", "Segoe UI", Roboto, "Helvetica Neue", Arial,
    sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  letter-spacing: 2px;
  text-transform: uppercase;
  color: #3a5a7e;
  background: #fff;
}

.boarding-text {
  font-size: 14px;
  color: #3a5a7e;
  font-weight: bold;
}

.boarding-price {
  font-size: 28px;
  color: #3a5a7e;
  font-weight: bold;
  text-transform: initial;
}

.boarding-price-sub {
  font-size: 14px;
  letter-spacing: initial;
  text-transform: initial;
}

.boarding-promo-price {
  color: #7bdfc3;
}

.boarding-promo-flights {
  font-size: 18px;
}

.boarding-pass-header {
  background: #3a5a7e;
  color: #fff;
  padding: 15px 0px;
  font-size: 24px;
  font-weight: bold;
}

.boarding-pass-rules {
  display: flex;
}

.boarding-pass-rules-membership,
.boarding-pass-rules-flights {
  flex: 1;
  padding: 20px 0px;
  border-bottom: 1px dashed #ff9c80;
}

.boarding-pass-rules-flights img {
  width: 80%;
  margin-top: 10px;
}

.boarding-pass-rules-membership {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-right: 0.5px dashed #ff9c80;
}

.boarding-pass-rules-flights {
  border-left: 0.5px dashed #ff9c80;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.boarding-pass-flight-credits {
  display: flex;
  flex-direction: column;
  padding: 20px 0px 5px 0px;
  border-bottom: 1px dashed #ff9c80;
}

.flight-price-flex {
  display: flex;

  h2 {
    font-size: 32px;
  }
  p {
    text-align: center;
  }
}

.boarding-pass-subscription {
  display: flex;
  flex-direction: column;
  padding: 20px 0px;
}

.boarding-pass-flight-period {
  border-bottom: 1px dashed #ff9c80;
  padding: 20px 5px;
}

.membership-start-date {
  font-size: 24px;
}

.how-it-works-techstars {
  background: rgb(255, 255, 232);
}

.no-pad-up {
  margin-top: -60px;
}

.pastterms {
  font-size: 14px;
}

.skyhi-pricing-rules {
  color: #385a80;
  font-size: 20px;
  font-weight: bold;
}

.describe h3 {
  text-align: left;
  font-size: 24px;
  font-weight: bold;
}

.pricing-bottom {
  width: 33%;
  margin: 0 auto;
  padding: 40px 0px;
}

@media (max-width: 992px) {
  .pricing-bottom {
    width: 80%;
  }
}

.invite-linkback {
  background: blue;
  display: flex;
  padding: 20px;
  color: #fff;
  text-align: center;
  position: fixed;
  bottom: 0px;
  width: 100%;
  z-index: 1000;
}

.ivnite-linkback.cta {
  width: 100%;
}

.placesimages {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.placeimage {
  flex: 1;
  padding: 10px 30px;
}

.credits {
  font-size: 22px;
  width: 80%;
  margin: 0 auto;
}

.onboarding-page-flow {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.react-toggle {
  touch-action: pan-x;

  display: inline-block;
  position: relative;
  cursor: pointer;
  background-color: transparent;
  border: 0;
  padding: 0;

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
}

.react-toggle-screenreader-only {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.react-toggle--disabled {
  cursor: not-allowed;
  opacity: 0.5;
  -webkit-transition: opacity 0.25s;
  transition: opacity 0.25s;
}

.react-toggle-track {
  width: 50px;
  height: 24px;
  padding: 0;
  border-radius: 30px;
  background-color: #4d4d4d;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.react-toggle:hover:not(.react-toggle--disabled) .react-toggle-track {
  background-color: #000000;
}

.react-toggle--checked .react-toggle-track {
  background-color: #19ab27;
}

.react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track {
  background-color: #128d15;
}

.react-toggle-track-check {
  position: absolute;
  width: 14px;
  height: 10px;
  top: 0px;
  bottom: 0px;
  margin-top: auto;
  margin-bottom: auto;
  line-height: 0;
  left: 8px;
  opacity: 0;
  -webkit-transition: opacity 0.25s ease;
  -moz-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease;
}

.react-toggle--checked .react-toggle-track-check {
  opacity: 1;
  -webkit-transition: opacity 0.25s ease;
  -moz-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease;
}

.react-toggle-track-x {
  position: absolute;
  width: 10px;
  height: 10px;
  top: 0px;
  bottom: 0px;
  margin-top: auto;
  margin-bottom: auto;
  line-height: 0;
  right: 10px;
  opacity: 1;
  -webkit-transition: opacity 0.25s ease;
  -moz-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease;
}

.react-toggle--checked .react-toggle-track-x {
  opacity: 0;
}

.react-toggle-thumb {
  transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  position: absolute;
  top: 1px;
  left: 1px;
  width: 22px;
  height: 22px;
  border: 1px solid #4d4d4d;
  border-radius: 50%;
  background-color: #fafafa;

  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;

  -webkit-transition: all 0.25s ease;
  -moz-transition: all 0.25s ease;
  transition: all 0.25s ease;
}

.react-toggle--checked .react-toggle-thumb {
  left: 27px;
  border-color: #19ab27;
}

.react-toggle--focus .react-toggle-thumb {
  -webkit-box-shadow: 0px 0px 3px 2px #0099e0;
  -moz-box-shadow: 0px 0px 3px 2px #0099e0;
  box-shadow: 0px 0px 2px 3px #0099e0;
}

.react-toggle:active:not(.react-toggle--disabled) .react-toggle-thumb {
  -webkit-box-shadow: 0px 0px 5px 5px #0099e0;
  -moz-box-shadow: 0px 0px 5px 5px #0099e0;
  box-shadow: 0px 0px 5px 5px #0099e0;
}

.consent-up {
  margin-top: -80px;
}

.compliance-switch {
  display: flex !important;
  font-size: 14px;
  text-align: right;
}

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

/* Hide default HTML checkbox */
.switch input {
  display: none;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #2196f3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.label-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.slider-flex {
  margin-bottom: 0px !important;
}

.displaynone {
  display: none;
}
.nav-item.dropdown {
  bottom: 0px;
}

.navbar-nav .dropdown-menu {
  top: 0px;
}

/* Members */
.memberbox {
  background: #ff9c80;
  padding: 40px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 15px 0px;
  h2 {
    color: #fff;
  }
}

.mobile-pad {
  padding: 10px 10px;
}

/* SkyHi Info Page */

.skyhi-info-page {
  h2 {
    font-size: 28px;
  }
}

.skyhi-info-apps {
  width: 75%;
  margin: 0 auto;
}

.back-arrow {
  text-align: right;
  display: block;
  color: #385a80;
  font-weight: bold;
  padding-right: 20px;
  cursor: pointer;
  a {
    color: #385a80;
    &:hover {
      text-decoration: none;
    }
  }
}

.payment-flow-pricing-list {
  p {
    line-height: 19px;
  }
}

.show-promo {
  padding: 10px 0px;
  font-weight: bold;
  cursor: pointer;
  .have-promo {
    color: #385a80;
  }
  .no-promo {
    color: #385a80;
    h4 {
      display: none;
    }
  }
}

.promo-success {
  color: green;
  margin-top: -20px;
  padding-left: 10px;
}

.promo-error {
  color: red;
  margin-top: -20px;
  padding-left: 10px;
}

@media (max-width: 375px) {
  .bottom-divider {
    margin-left: 10px;
    margin-right: 10px;
  }
}

.bottom-divider {
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: 2px dashed #385a80;
}

.promo-box-top {
  border: 4px solid #ff9c7e;
  padding: 20px;
  margin-bottom: 10px;

  .boarding-pass-rules-membership {
    border: none;
  }

  .promo-text-top {
    background: #385a80;
    padding: 10px;
    h3 {
      color: white;
      font-size: 26px;
    }
  }

  span {
    color: #3a5a7e;
  }
}

.price-tag p {
  float: right;
  background: #35aefb;
  padding: 0 5px;
  color: #fff;
  font-family: "Graphik Bold", "Segoe UI", Roboto, "Helvetica Neue", Arial,
    sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 18px;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
}

.map {
  background: rgb(255, 255, 232);
  padding: 100px 0px;
}
.map p {
  font-size: 28px;
  padding: 4px 0px;
}
.map h4 {
  margin-top: 1rem;
}
.map .flex-list {
  color: #3a5a7e;

  span {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
}
.map .flex-list li {
  font-size: 24px;
}
.map .flex-list li:after {
  background-color: #3a5a7e;
  margin-bottom: 2.5px;
}

.map .flex-list li:last-child:after {
  background-color: transparent;
}

.destinationsbar {
  background-color: #ff9c80;
  color: #fff;
  font-size: 28px;
  font-family: "Graphik Bold", "Segoe UI", Roboto, "Helvetica Neue", Arial,
    sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  padding: 10px 0;

  a {
    color: #fff;
    text-decoration: underline;
  }
}

/* Membership Account Pages */
.memberPage {
  background: #ffffe9;
  padding: 20px 0px;

  .arrow-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-content: flex-end;
  }
}

.yellow-border {
  padding-top: 10px;
  border-bottom: 5px dashed #f8d948;
}

.skyhi-basics {
  background: rgb(255, 255, 233);
  padding: 40px 0px;
}

.skyhi-basics h2 {
  font-size: 40px;
}

.skyhi-basics-rates {
  background: rgb(255, 213, 181);
  padding: 40px 0px;
}
.skyhi-basics-rates h2 {
  font-size: 40px;
  padding: 0px 100px;
}

.basics-flex {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
}

.basics {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 33.333333%;
  padding-bottom: 20px;
}

.basics h3 {
  font-size: 26px;
  text-align: center;
  line-height: 1.2;
}

.basics-pad {
  padding: 20px 80px;
}

.basics-rules-pad {
  padding: 20px 60px;
}

.bar {
  width: 100%;
  margin: 0 auto;
  padding: 0px 10vh;
}
.bar h3 {
  background: rgb(58, 89, 125);
  text-align: center;
  font-size: 30px;
  color: rgb(255, 213, 181);
  padding: 20px 0px;
  line-height: 1.2;
}

@media (min-width: 992px) {
  .infographic-mobile {
    display: none;
  }
  .infographic-desktop {
    background: rgb(187, 239, 254);
  }
}

@media (max-width: 992px) {
  .infographic-desktop {
    display: none;
  }
  .infographic-mobile {
    background: rgb(254, 213, 183);
  }
}

@media (max-width: 992px) {
  .basics-flex {
    flex-direction: column;
  }
  /* .basics {
    width: auto;
    height: auto;
    margin-bottom: 30px;
  } */
  .price-table-large {
    margin-top: -30px;
  }
  .basics {
    width: 100%;
    padding: 20px 40px;
  }
  .basics-pad,
  .basics-rules-pad {
    padding: 10px 0px;
  }
  .skyhi-basics-rates h2 {
    padding: 0px;
  }
  .bar {
    padding: 0px;
  }
}

.authbadge {
  padding-left: 20px;
}
.mobileauthbadge {
  display: none;
}
@media (max-width: 375px) {
  .authbadge {
    display: none;
  }
  .mobileauthbadge {
    display: flex;
    justify-content: center;
  }
  .mobile-submitpayment {
    text-align: center;
  }
}

.status-bar {
  position: fixed;
  z-index: 2;
  width: 100%;
  p {
    background-color: #ff9c80;
    text-align: center;
    color: #fff;
    padding: 10px;
  }
}

.nomatch-page {
  background: #fff4bf;
  background: -webkit-linear-gradient(top, #fff4bf, #ffffff);
  background: -o-linear-gradient(top, #fff4bf, #ffffff);
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#fff4bf),
    to(#ffffff)
  );
  background: linear-gradient(to bottom, #fff4bf, #ffffff);
}

.nomatch-flex {
  min-height: 90vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.nomatch-text {
  h2 {
    color: #ff9c80;
  }
}

.spinner-container {
  height: 45vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Account Page Updates */

.members-nav {
  background: rgb(254, 244, 195);
  ul.membernav {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .nav__links {
      display: inline-block;
      padding: 10px 20px;
      font-weight: bold;
      @media screen and (max-width: 504px) {
        padding: 10px 0px;
      }
      a {
        text-decoration: none;
        color: #385a80;
        padding: 10px 10px;
        transition: all 0.3s ease 0s;

        @media screen and (max-width: 384px) {
          padding: 10px 6px;
          font-size: 12px;
        }

        &:hover {
          color: #ff9c80;
          border-bottom: 4px #ff9c80 solid;
        }
      }
    }
    .membernav-active {
      a {
        color: #ff9c80;
        border-bottom: 4px #ff9c80 solid;
      }
    }
  }
}

.membership {
  .top-hero {
    padding: 2rem;
    // background: rgb(187,239,254);
    // background: linear-gradient(90deg, rgba(187,239,254,1) 0%, rgba(255,156,128,1) 35%, rgba(255,255,233,1) 100%);
  }
  h2 {
    font-size: 1.5em;
    color: #fff;
  }
}

.account-info-box {
  border-radius: 24px;
  padding: 20px 40px;
}
.info-group {
  // border-bottom: 4px #ff9c80 dashed;
  .subtitle {
    font-size: 0.8rem;
    text-transform: uppercase;
  }
}

p.subtitle {
  font-weight: bold;
  padding-bottom: 4px;
}

.bookings-box {
  background: #fff;
  margin: 10px 0px;
  padding: 20px 10px;
  border-radius: 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h2 {
    font-size: 1.5rem;
  }

  .trip-details {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1;
    .flight {
      border-top: 2px dashed black;
      padding-top: 20px;
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
      align-items: center;
      width: 92%;
      img {
        width: 20%;
      }
    }
    .trip-duration {
      padding-bottom: 20px;
    }
    .confirmation {
      border-top: 2px dashed black;
      padding: 20px 0px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      h2 {
        padding-bottom: 5px;
        font-size: 1.2rem;
      }
    }
    .flight-times {
      padding: 10px 0px;
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
      // align-items: center;
      width: 100%;
      h3 {
        font-size: 1rem;
      }
      border-top: 2px dashed black;
      border-bottom: 2px dashed black;
    }
  }
}

.flight__segment {
  width: 100%;
  border-top: 2px dashed black;
  padding: 20px 0px;

  .segment-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    max-width: 252px;
  }

  .segment__airline {
    width: 50%;
    margin: 0 auto;
  }

  .segment {
    font-weight: bold;
    color: #385a80;
  }
  .segment__time {
    width: 100%;
    text-align: center;
    border-bottom: 1px solid #000;
    line-height: 0.1em;
    margin: 10px 10px;

    span {
      background: #fff;
      padding: 0 10px;
    }
  }

  .segment-layover {
    border-top: 2px dashed black;
    padding-top: 15px;
    margin-top: 10px;
    text-align: center;
  }
}

.no__bookings h2 {
  font-size: 1.5rem;
}

.no__bookings img {
  margin: 0 auto;
}

/* User Account Card Styles */

.flexGroup {
  display: flex;
  justify-content: center;
  align-items: center;
}

img.profile-img {
  width: 75px;
  height: 75px;
  border-radius: 50%;
}

.nameGroup {
  padding: 20px;
}

.flexRow {
  display: flex;
  .flex-icons-download {
    flex-direction: row;
  }
}

/* Republic Banner */
.republic__banner {
  background-color: #ff9c80;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: center;
  padding: 10px;
  position: fixed;
  width: 100%;
  z-index: 10;

  p {
    flex: 1;
    text-align: center;
    color: #fff;
    font-size: 16px;

    a {
      text-decoration: underline;
      color: #fff;
    }
  }

  .republic__banner--close {
    font-weight: bold;
    color: #fff;
    &:hover {
      cursor: pointer;
    }
  }
}

/* Republic Popup */

.popup-content {
  background: transparent !important;
  border: transparent !important;
}
.republic__popup {
  position: relative;
  .close {
    position: absolute;
    right: 10px;
  }
  @media screen and (min-width: 992px) {
    max-width: 40%;
    margin: 0 auto;
  }

  width: 90vw;
  margin: 0 auto;
  background-color: #f0fcff;
  .content {
    // padding: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    p {
      font-weight: bold;
      line-height: 1.5;
      .larger {
        font-size: 28px;
        font-weight: bold;
        color: #ff9c80;
      }
    }
    .republic__link {
      color: #ff9c80;
    }
    .banner {
      background: #ff9c80;
      padding: 20px 40px;
      width: 100%;
      h2 {
        color: #fff;
      }
    }
    p {
      padding: 0px 40px;
      &:first-of-type {
        margin-top: 20px;
      }
      &:last-child {
        padding-bottom: 20px;
      }
    }
  }
}

/* SkyHi Status */

.status__page {
  .container {
    padding: 40px 4px;
    p {
      margin-top: 20px;
      @media screen and (max-width: 992px) {
        margin-left: 10px;
      }
    }
    input {
      border: 4px solid #385a80;
      padding: 25px 10px;
      margin-top: 20px;
      margin-right: 10px;
      width: 50%;
      &:last-child {
        display: block;
        margin-bottom: 20px;
      }
      @media screen and (max-width: 992px) {
        width: 100% !important;
      }
    }
    .input__field:focus {
      border: 4px solid #385a80 !important;
      border-color: initial;
    }
  }
}
